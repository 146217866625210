// import React, { useState } from "react";
// import styles from "./SeprateDet.module.css";
// import { FaFileDownload } from "react-icons/fa";

// import LoanDet from "./LoanDet";
// const SeprateDet = ({ dashboardList }) => {
//   console.log("dashboardList", dashboardList);
//   return (
//     <div>
//       <div className={styles.updownloadcon}>
//         <button className={styles.dnldbtnforreport}>
//           <FaFileDownload size={10} style={{ paddingRight: "3px" }} />
//           Download Report
//         </button>
//       </div>
//       <div className={styles.detcon}>
//         <LoanDet details={dashboardList} />
//       </div>
//     </div>
//   );
// };

// export default SeprateDet;
import React, { useState } from "react";
import styles from "./SeprateDet.module.css";
import { FaFileDownload } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import CoApplicantDetails from "./CoApplicantDetails";
import GuarantorDetails from "./GuarantorDetails";
import BorrowerDetails from "./BorrowerDetails";
import { Backdrop } from "@mui/material";
import GenralDoc from "./GenralDoc";
import LoanDet from "./LoanDet";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
const SeprateDet = ({ dashboardList, borrowerId }) => {
  const [perm, setPerm] = useState([]);
  const [brw, setBrw] = useState(false);
  const [lon, setLoan] = useState(true);
  const [app, setApp] = useState(false);
  const [gua, setGua] = useState(false);
  const BrwHandler = () => {
    setBrw((val) => !val);
    setApp(false);
    setGua(false);
    setLoan(false);
  };
  const AppHandler = () => {
    setBrw(false);
    setApp((val) => !val);
    setGua(false);
    setLoan(false);
  };
  const GuaHandler = () => {
    setBrw(false);
    setApp(false);
    setGua((val) => !val);
    setLoan(false);
  };
  const LonHandler = () => {
    setBrw(false);
    setApp(false);
    setGua(false);
    setLoan((val) => !val);
  };
  const [add, setAdd] = useState(false);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  console.log("dashboardList", dashboardList);
  const fetchPermissions = async () => {
    const response = await api.get("admin/get-sub-menu-permissions/13");
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions"],
    queryFn: fetchPermissions,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  const { data: permissionsData } = PermissionsResponse;
  console.log("perm", perm);
  const downloadReport = async () => {
    try {
      const response = await api.get(`admin/download-folder/${borrowerId}`);

      if (response.data.status === "success" && response.data.data.file_path) {
        const filePath = response.data.data.file_path;
        console.log("filePath", filePath);
        // Fetch the ZIP file from the file path
        const fileResponse = await fetch(filePath);
        const blob = await fileResponse.blob();

        // Create a downloadable link
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", `${borrowerId}.zip`);
        document.body.appendChild(link);
        link.click();

        // Clean up the temporary URL and link element
        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
      } else {
        console.error("Error: File path not found in response.");
      }
    } catch (error) {
      console.error("Error downloading the file:", error);
      // Handle the error (e.g., show a notification)
    }
  };
  const downloadAgreement = async () => {
    try {
      const response = await api.get(
        `admin/download-agreement-file/${borrowerId}`
      );

      if (
        response.data.status === "success" &&
        response.data.data.download_url
      ) {
        const downloadUrl = response.data.data.download_url;
        console.log("downloadUrl", downloadUrl);

        // Fetch the file from the download URL
        const fileResponse = await fetch(downloadUrl);
        const blob = await fileResponse.blob();

        // Create a downloadable link
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "final_signed_agreement.pdf"); // Customize the file name as needed
        document.body.appendChild(link);
        link.click();

        // Clean up the temporary URL and link element
        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
      } else {
        console.error("Error: Download URL not found in response.");
      }
    } catch (error) {
      console.error("Error downloading the report:", error);
      // Handle the error (e.g., show a notification)
    }
  };
  const downloadCamSheet = async () => {
    try {
      const response = await api.get(
        `admin/cam-sheet-doc-download/${borrowerId}`
      );

      if (
        response.data.status === "success" &&
        response.data.data.download_url
      ) {
        const downloadUrl = response.data.data.download_url;
        console.log("downloadUrl", downloadUrl);

        const fileResponse = await fetch(downloadUrl);
        const blob = await fileResponse.blob();

        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "camSheet.xlsx");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
      } else {
        console.error("Error: Download URL not found in response.");
      }
    } catch (error) {
      console.error("Error downloading the report:", error);
    }
  };
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <GenralDoc
          addHandle={addHandle}
          doc={dashboardList?.documents || {}}
          type="General"
          borrowerId={borrowerId}
        />
      </Backdrop>
      <div
        className={styles.updownloadcon}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex", gap: "12px" }}>
          {perm?.includes("export") && (
            <button
              onClick={downloadReport}
              type="button"
              className={styles.dnldbtnforreport}
            >
              <FaFileDownload size={10} style={{ paddingRight: "3px" }} />
              Borrower Documents Download
            </button>
          )}
          {perm?.includes("export") && (
            <button
              onClick={downloadAgreement}
              type="button"
              className={styles.dnldbtnforreport}
            >
              <FaFileDownload size={10} style={{ paddingRight: "3px" }} />
              Download Agreement
            </button>
          )}
          {perm?.includes("export") && (
            <button
              onClick={downloadCamSheet}
              type="button"
              className={styles.dnldbtnforreport}
            >
              <FaFileDownload size={10} style={{ paddingRight: "3px" }} />
              Download CAM Sheet
            </button>
          )}
        </div>
        <button
          type="button"
          style={{ background: "#12468a" }}
          onClick={addHandle}
          className={styles.dnldbtnforreport}
        >
          General Documents
        </button>
      </div>
      <div className={styles.detcon}>
        <div
          className={styles.btndvtoop}
          onClick={LonHandler}
          style={{ background: lon ? "#3577f1" : "#fff" }}
        >
          <span
            className={styles.btndvtxt}
            style={{ color: lon ? "#fff" : "#212529" }}
          >
            {" "}
            Loan Details
          </span>

          {/* Co-Applicant Details Guarantor Details */}
          {lon ? (
            <IoIosArrowUp style={{ color: brw ? "#fff" : "#212529" }} />
          ) : (
            <IoIosArrowDown style={{ color: brw ? "#fff" : "#212529" }} />
          )}
        </div>
        {lon && <LoanDet details={dashboardList} />}
        <div
          className={styles.btndvtoop}
          onClick={BrwHandler}
          style={{ background: brw ? "#3577f1" : "#fff" }}
        >
          <span
            className={styles.btndvtxt}
            style={{ color: brw ? "#fff" : "#212529" }}
          >
            {" "}
            Borrower Details
          </span>

          {/* Co-Applicant Details Guarantor Details */}
          {brw ? (
            <IoIosArrowUp style={{ color: brw ? "#fff" : "#212529" }} />
          ) : (
            <IoIosArrowDown style={{ color: brw ? "#fff" : "#212529" }} />
          )}
        </div>
        {brw && (
          <BorrowerDetails
            Detailsb={dashboardList}
            borrowerId={borrowerId}
            // addDetail={dashboardList?.borrower_details}
          />
        )}
        <div
          className={styles.btndvtoop}
          onClick={AppHandler}
          style={{ background: app ? "#3577f1" : "#fff" }}
        >
          <span
            className={styles.btndvtxt}
            style={{ color: app ? "#fff" : "#212529" }}
          >
            {" "}
            Co-Applicant Details
          </span>
          {app ? (
            <IoIosArrowUp style={{ color: app ? "#fff" : "#212529" }} />
          ) : (
            <IoIosArrowDown style={{ color: app ? "#fff" : "#212529" }} />
          )}
        </div>
        {app && (
          <CoApplicantDetails
            Detailsb={dashboardList}
            borrowerId={borrowerId}
          />
        )}
        <div
          className={styles.btndvtoop}
          onClick={GuaHandler}
          style={{ background: gua ? "#3577f1" : "#fff" }}
        >
          <span
            className={styles.btndvtxt}
            style={{ color: gua ? "#fff" : "#212529" }}
          >
            {" "}
            Guarantor Details
          </span>
          {gua ? (
            <IoIosArrowUp style={{ color: gua ? "#fff" : "#212529" }} />
          ) : (
            <IoIosArrowDown style={{ color: gua ? "#fff" : "#212529" }} />
          )}
        </div>
        {gua && (
          <GuarantorDetails Detailsb={dashboardList} borrowerId={borrowerId} />
        )}
      </div>
    </div>
  );
};

export default SeprateDet;
