import React, { useState } from "react";
import classes from "./Language.module.css";
import { useNavigate } from "react-router-dom";
import AdditionalSeven from "./AdditionalSeven";
import Thankyou from "../../../icons/Thnaks.png";
const SevenStep = ({ setActiveStep }) => {
  const [show, setShow] = useState(false);
  const HandleShow = () => {
    setShow(true);
  };
  return (
    <div className={classes.tablco}>
      {!show && (
        <div>
          <img src={Thankyou} style={{ width: "300px" }} />
          <p className={classes.thnktt}>
            Thanks For Submitting Your Application.
          </p>
          <p className={classes.btmthnktt}>
            Wait,Our AI Based Algorithm will Recommend You Best Lender With
            Customized Offer For You.
          </p>
          <button
            type="button"
            className={classes.Subbtn}
            style={{ width: "80px", background: "#45cb85" }}
            onClick={HandleShow}
          >
            Continue
          </button>
        </div>
      )}
      {show && <AdditionalSeven />}
    </div>
  );
};

export default SevenStep;
