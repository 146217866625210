import "./App.css";
import { RouterProvider } from "react-router-dom";
import React, { Suspense } from "react";
import routes from "./routes/routes";
import { AuthProvider } from "./context/AuthContext";
import { ToastProvider } from "./context/ToastProvder";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import queryClient from "./reactQueryClient"; //
import { LoanProvider } from "./context/LoanContext";
// const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ToastProvider>
          <LoanProvider>
            <div className="App">
              <Suspense fallback={<div>Loading...</div>}>
                <RouterProvider router={routes} />
              </Suspense>
            </div>
          </LoanProvider>
        </ToastProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
