import React, { useContext, useEffect, useState } from "react";
import classes from "./Language.module.css";
import { useForm } from "react-hook-form";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { LoanContext } from "../../../context/LoanContext";
import { useToast } from "../../../context/ToastProvder";
const AdditionFive = ({ productId }) => {
  const {
    userD,
    handleNext,
    borrId,
    dataFromAdhar,
    setDataFromAdhar,
    dataFromAdharCo,
  } = useContext(LoanContext);
  const { showToast } = useToast();
  const [add, setAdd] = useState(null);
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (borrId) {
      const fetchBorrowerDetails = async () => {
        try {
          const response = await api.post("admin/get-user-address-details", {
            borrower_id: borrId,
            user_type: "COAPPLICANT",
          });
          console.log(response.data.data);
          setAdd(response.data.data);
        } catch (error) {
          console.error("Error fetching borrower details:", error);
        }
      };

      fetchBorrowerDetails();
    }
  }, [borrId]);
  const [{ data: resType }, { data: occType }, { data: vehType }] = useQueries({
    queries: [
      {
        queryKey: ["resType"],
        queryFn: () => api.get("get-resident-types").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["occType"],
        queryFn: () => api.get("get-occupations").then((res) => res.data),
        initialData: [],
      },

      {
        queryKey: ["vehType"],
        queryFn: () => api.get("get-vehicle-types").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  const onSubmit = async (data) => {
    const { monthly_income, expected_monthly_income } = data;
    if (expected_monthly_income > monthly_income) {
      showToast(
        "Expected monthly income cannot be greater than monthly income.",
        "error"
      );
      return;
    }
    let payload = {
      borrower_id: borrId,
      resident_type: data.resident_type,
      occupation_type: data.occupation_type,
      current_occupation: data.current_occupation,
      current_address: data.current_address,
      permanent_address: data.permanent_address,
      years_living: data.years_living,
      monthly_income: data.monthly_income,
      expected_monthly_income: data.expected_monthly_income,
      other_income: data.other_income,
      extra_source_of_income: data.extra_source_of_income,
      total_yearly_income: data.total_yearly_income,
    };

    console.log("Payload to be submitted:", payload);

    try {
      const response = await api.post(
        "admin/store-coapplicant-occupation-details",
        payload
      );
      const { status, message } = response.data;
      console.log("response.data", response.data);
      if (response.status === 200 || response.data.status === "success") {
        console.log("API call successful:", response.data);

        showToast(message, status);
        handleNext();
      }
    } catch (error) {
      const { message, data: errorDetails } = error.response.data;
      showToast(message, "error");
      console.error(
        "Error while submitting data:",
        error.response?.data || error.message
      );
    }
  };
  useEffect(() => {
    if (add || dataFromAdharCo) {
      const address = dataFromAdharCo?.address || add?.single_line_address;
      setValue("permanent_address", address || "");
    }
  }, [dataFromAdharCo, add, setValue]);
  const monthlyIncome = watch(
    productId === "4" ? "monthly_income" : "expected_monthly_income"
  );
  // useEffect(() => {
  //   if (monthlyIncome) {
  //     setValue("total_yearly_income", monthlyIncome * 12);
  //   }
  // }, [monthlyIncome, setValue]);
  useEffect(() => {
    if (monthlyIncome) {
      setValue("total_yearly_income", monthlyIncome * 12);
    } else {
      setValue("total_yearly_income", null);
    }
  }, [monthlyIncome, setValue]);
  const isSameAddressChecked = watch("same_address");
  useEffect(() => {
    if (isSameAddressChecked) {
      setValue("current_address", watch("permanent_address"));
    } else {
      setValue("current_address", ""); // Clear current address if unchecked
    }
  }, [isSameAddressChecked, watch("permanent_address"), setValue]);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        marginTop: "10px",
        borderTop: "1px solid #ddd",
        padding: "15px 0",
      }}
    >
      {" "}
      <hr style={{ border: "0.5px solid #ddd", margin: "20px 0" }} />
      <p className={classes.applheader}>CO-APPLICANT OCCUPATIONS :</p>
      <div className={classes.totalcheckconnn}>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Resident Type<span className={classes.requ}>*</span>
          </label>

          <select
            {...register("resident_type", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option value="">--Select Resident Type--</option>
            {resType?.data?.map((row) => (
              <option value={row}>{row}</option>
            ))}
          </select>
          {errors.resident_type && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Permanent Address<span className={classes.requ}>*</span>
          </label>
          <textarea
            style={{ height: "35px" }}
            placeholder="Enter Permanent Address"
            {...register("permanent_address", { required: true })}
            type="text"
            disabled
            className={classes.inputstst}
          />

          {errors.permanent_address && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Present/Communication Address<span className={classes.requ}>*</span>
          </label>
          <textarea
            style={{ height: "35px" }}
            placeholder="Enter Present/Communication Address"
            {...register("current_address", { required: true })}
            type="text"
            className={classes.inputstst}
          />

          {errors.current_address && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div style={{ paddingTop: "15px", textAlign: "left" }}>
          <input
            {...register("same_address")}
            type="checkbox"
            className={classes.inputstst}
          />

          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              position: "relative",
              bottom: "3px",
            }}
          >
            Same as Permanent Address
          </label>
          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Years Living<span className={classes.requ}>*</span>
          </label>

          <input
            {...register("years_living", {
              required: "Minimum 3 Years Required",
              min: {
                value: 3,
              },
            })}
            // {...register("years_living", { required: true })}
            type="number"
            className={classes.inputstst}
          />
          {errors.years_living && (
            <span className={classes.error}>Minimum 3 Years Required.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Occupation Type<span className={classes.requ}>*</span>
          </label>

          <select
            {...register("occupation_type", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option value="">--Select Occupation Type--</option>
            {occType?.data?.map((row) => (
              <option value={row.id}>{row.occupation}</option>
            ))}
          </select>
          {errors.occupation_type && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Current Occupation<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Current Occupation"
            {...register("current_occupation", {
              required: true,
              validate: (value) =>
                /^[A-Za-z\s]+$/.test(value) ||
                "Only letters and spaces are allowed.",
            })}
            // {...register("current_occupation", { required: true })}
            type="text"
            className={classes.inputstst}
          />
          {errors.current_occupation && (
            <span className={classes.error}>
              {errors.current_occupation.message || "This Field is Missing."}
            </span>
          )}
        </div>
        {/* <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Driving Exprience<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Driving Exprience"
            {...register("years_living", {
              required: "This Field is Missing.",
              min: {
                value: 1,
              },
            })}
            {...register("perm", { required: true })}
            type="number"
            className={classes.inputstst}
          />
          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div> */}
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Vehicle Type<span className={classes.requ}>*</span>
          </label>

          <select
            {...register("name", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option value="">--Select Vehicle Type--</option>
            {vehType?.data?.map((row) => (
              <option value={row}>{row}</option>
            ))}
          </select>
          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Monthly Income<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Monthly Income"
            {...register("monthly_income", {
              required: "This Field is Missing",
              min: {
                value: 15000,
                message: "Income must be greater than/equal to 15,000",
              },
            })}
            type="number"
            className={classes.inputstst}
          />

          {errors.monthly_income && (
            <span className={classes.error}>
              {errors.monthly_income.message}
            </span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Expected Monthly Income<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Expected Monthly Income"
            {...register("expected_monthly_income", {
              required: "This Field is Missing",
              min: {
                value: 15000,
                message: "Income must be greater than/equal to 15,000",
              },
            })}
            {...register("expected_monthly_income", { required: true })}
            type="number"
            className={classes.inputstst}
          />

          {errors.expected_monthly_income && (
            <span className={classes.error}>
              {errors.expected_monthly_income.message}
            </span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Other Income
          </label>
          <input
            placeholder="Enter Other Income"
            {...register("other_income")}
            type="number"
            className={classes.inputstst}
          />
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Source
          </label>
          <input
            placeholder="Enter Extra Source of Income"
            {...register("extra_source_of_income")}
            type="text"
            className={classes.inputstst}
          />
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Total Yearly Income<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Total Yearly Income"
            {...register("total_yearly_income", { required: true })}
            type="number"
            disabled
            className={classes.inputstst}
          />

          {errors.total_yearly_income && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      >
        {/* {!defaultValue && ( */}
        <button
          type="submit"
          className={classes.Subbtn}
          style={{ width: "80px", marginTop: "10px" }}
        >
          Submit
        </button>
        {/* )} */}
      </div>
    </form>
  );
};

export default AdditionFive;
