import React, { useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { useLocation, useNavigate } from "react-router-dom";

import DealerForm from "./DealerForm";

const AddDealerAuthority = () => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(2);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const location = useLocation();
  const { val, typeV, peri } = location.state || "";
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <Breadcum
          pageName={val ? "Create Dealer Authority" : "Update Dealer Authority"}
        />
        <div className={classes["main-body"]} style={{ padding: "15px" }}>
          <div className={classes.tablco}>
            <DealerForm
              defaultValue={val}
              typeV={typeV || ""}
              peri={peri || ""}
              val={val}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDealerAuthority;
