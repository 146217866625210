import React, { useContext, useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
import PropTypes from "prop-types";
import { Input as BaseInput } from "@mui/base/Input";
import { Box, styled } from "@mui/system";

import { useNavigate } from "react-router-dom";
import { useToast } from "../../../context/ToastProvder";
import OtpInput from "../VarifyItsYou/OtpInput";
import { LoanContext } from "../../../context/LoanContext";
import { AuthContext } from "../../../context/AuthContext";
const AdharVer = ({
  addHandle,
  borrId,
  userD,
  handleNext,
  setAdharStatus,
  aadharResponse,
  aadharNum,
  setDataFromAdhar,
  type,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    setValue,
  } = useForm();
  const { userId } = useContext(LoanContext);
  const { ver } = useContext(AuthContext);
  const navigate = useNavigate();
  const [Uerr, setUErr] = useState(null);
  const [err, setErr] = useState(null);
  const { showToast } = useToast();
  const onSubmit = async (data) => {
    let otpValue = Object.values(data.otp).join("");
    otpValue = Number(otpValue);
    let payload = {
      otp: otpValue,
      user_id: userD?.id || userId,
      borrower_id: borrId,
      applicant_type: type,
      transactionId: aadharResponse?.transactionId,
      fwdp: aadharResponse?.fwdp,
      aadhar_number: aadharNum,
      codeVerifier: aadharResponse?.codeVerifier,
    };

    try {
      const response = await api.post(
        //  "v2/verify-aadhar-using-otp",
        `${ver}admin-verify-aadhar-using-otp`,
        // `${ver}verify-aadhar-using-otp`,
        payload
      );
      const { status, message, data } = response.data;
      console.log("data from adha", data);
      // setDataFromAdhar()
      if (status === "success") {
        reset();
        setDataFromAdhar(data);
        setAdharStatus(true);
        showToast(message, "success");
        addHandle();
        // handleNext();
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      const errorMessage =
        error.response?.data?.message || "Something went wrong.";
      showToast(errorMessage, "error");
      setErr(errorMessage);
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      style={{ width: "340px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>Enter OTP</h3>

        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <OtpInput register={register} name="otp" length={6} />
        </div>
      </div>

      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            {/* <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button> */}
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AdharVer;
