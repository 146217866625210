import React, { useContext, useEffect, useState } from "react";
import classes from "./Language.module.css";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import { useQueries, useQuery } from "@tanstack/react-query";
import { LoanContext } from "../../../context/LoanContext";
import api from "../../Common/api";
import { FaCheckCircle } from "react-icons/fa";
import { RiCheckboxCircleFill } from "react-icons/ri";

const AdditionalTen = ({ proId, setInitialStep }) => {
  const { borrId, handleNext, setActiveStep } = useContext(LoanContext);
  const { data: relationList, refetch } = useQuery(
    ["rela", borrId],
    async () => {
      const payload = { borrower_id: borrId };
      const res = await api.post("admin/get-reference-list", payload);
      return res.data;
    },
    {
      enabled: !!borrId,
      initialData: [],
    }
  );
  const handleBackStp = () => {
    setInitialStep(false);
  };
  useEffect(() => {
    if (relationList) {
      if (proId === "4" && relationList?.data?.is_reference_verified_1) {
        handleNext();
        console.log("one true");
      } else if (
        proId !== "4" &&
        relationList?.data?.is_reference_verified_1 === true &&
        relationList?.data?.is_reference_verified_2 === true
      ) {
        // handleNext();
        setActiveStep(10);
        console.log("both condition is true");
      } else {
        console.log("References not verified.");
      }

      //  setCheckStatus(false); // Reset after checking
    }
  }, [relationList, proId]);
  return (
    <div>
      <div className={classes.totalcheckconnn}>
        {/* start */}
        <div
          className={classes.applylonffer}
          // onClick={() => handleEditRow("L3")}
        >
          <p
            style={{ padding: "6px", fontSize: "13px" }}
            className={classes.applyluptxt}
          >
            REFRENCE 1
          </p>
          <div className={classes.divfrtheredr}>
            <div className={classes.notAppre}>
              {relationList?.data?.is_reference_verified_1 ? (
                <RiCheckboxCircleFill
                  size="25"
                  style={{ background: "green", borderRadius: "100%" }}
                />
              ) : (
                <RxCrossCircled size="25" />
              )}
            </div>
            <div className={classes.applylonfthn}>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Name</p>
                <p className={classes.txtfooflbtoneffs}>
                  {relationList?.data?.reference_1}
                </p>
              </div>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Mobile</p>
                <p className={classes.txtfooflbtoneffs}>
                  {" "}
                  {relationList?.data?.mobile_1}
                </p>
              </div>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Relation</p>
                <p className={classes.txtfooflbtoneffs}>
                  {" "}
                  {relationList?.data?.relation_1}
                </p>
              </div>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Address</p>
                <p className={classes.txtfooflbtoneffs}>
                  {" "}
                  {relationList?.data?.address_1}
                </p>
              </div>
              {/* <div className={classes.divinffe}>
              <p className={classes.txtfoofl}>Rate of Interest</p>
              <p className={classes.txtfooflbtoneffs}>17.5%</p>
            </div> */}
            </div>
          </div>
        </div>
        {/* emd */}
        {proId !== "4" && (
          <div
            className={classes.applylonffer}
            // onClick={() => handleEditRow("L3)}
          >
            <p
              style={{ padding: "6px", fontSize: "13px" }}
              className={classes.applyluptxt}
            >
              REFRENCE 2
            </p>
            <div className={classes.divfrtheredr}>
              <div className={classes.notAppre}>
                {relationList?.data?.is_reference_verified_2 ? (
                  <RiCheckboxCircleFill
                    size="25"
                    style={{ background: "green", borderRadius: "100%" }}
                  />
                ) : (
                  <RxCrossCircled size="25" />
                )}
              </div>
              <div className={classes.applylonfthn}>
                <div className={classes.divinffdffg}>
                  <p className={classes.txtfoofl}>Name</p>
                  <p className={classes.txtfooflbtoneffs}>
                    {" "}
                    {relationList?.data?.reference_2}
                  </p>
                </div>
                <div className={classes.divinffdffg}>
                  <p className={classes.txtfoofl}>Mobile</p>
                  <p className={classes.txtfooflbtoneffs}>
                    {" "}
                    {relationList?.data?.mobile_2}
                  </p>
                </div>
                <div className={classes.divinffdffg}>
                  <p className={classes.txtfoofl}>Relation</p>
                  <p className={classes.txtfooflbtoneffs}>
                    {" "}
                    {relationList?.data?.relation_2}
                  </p>
                </div>
                <div className={classes.divinffdffg}>
                  <p className={classes.txtfoofl}>Address</p>
                  <p className={classes.txtfooflbtoneffs}>
                    {" "}
                    {relationList?.data?.address_2}
                  </p>
                </div>
                {/* <div className={classes.divinffe}>
              <p className={classes.txtfoofl}>Rate of Interest</p>
              <p className={classes.txtfooflbtoneffs}>17.5%</p>
            </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      >
        {/* {!defaultValue && ( */}
        <button
          type="button"
          className={classes.Subbtn}
          onClick={() => refetch()}
          style={{ width: "120px" }}
        >
          Check Status
        </button>
        <button
          type="button"
          className={classes.Subbtn}
          onClick={handleBackStp}
          style={{
            width: "120px",
            background: "rgb(69, 203, 133)",
            marginLeft: "10px",
          }}
        >
          Edit Refrence
        </button>
      </div>
    </div>
  );
};

export default AdditionalTen;
