import React, { useState, useEffect } from "react";
import styles from "./MonthBtn.module.css";
import { TbHexagons } from "react-icons/tb";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
const YearTotalDash = ({ perm }) => {
  const fetchPermissions = async () => {
    const response = await api.get("admin/get-sub-menu-permissions/12");
    return response.data;
  };
  const navigate = useNavigate();
  const handleSignIn = (val) => {};
  const fetchData = async (searchData) => {
    const payload = {};

    if (searchData) {
      payload.bankId = searchData.bankId || "";
    }

    const response = await api.get("get-yearly-count");
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: ["yearCount"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {},
  });
  const { isLoading, isFetching, data: yearCount } = DetailsResponse;

  return (
    <div style={{ marginTop: "20px", display: "grid" }}>
      <div className={styles.monthBoxes}>
        {/* {applicationData.map((app, index) => ( */}
        <div className={styles.ttldv} onClick={() => handleSignIn("")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>
              Total Application(YTD)
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.total_application}
              </p>
              <div style={{ background: "#299cdb" }} className={styles.icocont}>
                <TbHexagons size={28} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("1")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>
              Disbursed Application(YTD)
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.step1_count}
              </p>
              <div style={{ background: "#25a546" }} className={styles.icocont}>
                <TbHexagons size={28} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("2")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>
              Disbursed Amount(YTD)
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.step2_count}
              </p>
              <div style={{ background: "#45cb85" }} className={styles.icocont}>
                <TbHexagons size={28} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YearTotalDash;
