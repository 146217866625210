import React, { useContext, useEffect, useState } from "react";
import classes from "./Language.module.css";
import { useNavigate } from "react-router-dom";
import AdditionalSeven from "./AdditionalSeven";
import Thankyou from "../../../icons/Thnaks.png";
import AdditionalThirteen from "./AdditionalThirteen";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import { LoanContext } from "../../../context/LoanContext";
import { useToast } from "../../../context/ToastProvder";
const ThirteenStep = ({ setActiveStep }) => {
  const [intitialStep, setInitialStep] = useState(false);
  const [stp, setStp] = useState(null);
  const [showGen, setShowGen] = useState(true);
  const [showRegenButton, setShowRegenButton] = useState(false);
  const { borrId, dealerId } = useContext(LoanContext);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [{ data: relationList }] = useQueries({
    queries: [
      {
        queryKey: ["rela"],
        queryFn: async () => {
          const res = await api.get("get-emi-debit-consent-data");
          return res.data;
        },

        initialData: [],
      },
    ],
  });
  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          console.log("Loan Status:", response.data);

          // const { product_id } = response?.data;
          const { step } = response?.data?.step_details;
          setStp(step);
          if (step === 20) {
            setInitialStep(true);
          }
          if (step === 21) {
            setInitialStep(true);
            setShowGen(false);
            setShowRegenButton(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  const handleConcent = async (consent) => {
    try {
      const payload = {
        borrower_id: borrId,
        emi_debit_consent: consent,
      };

      const response = await api.post("admin/store-emi-debit-consent", payload);
      const { status, message } = response.data;
      if (response.status === 200 || response.data.status === "success") {
        console.log("API call successful:", response.data);
        setInitialStep(true);
        if (consent === "NO") {
          navigate("/dashboard");
        }
        showToast(message, status);
      }
    } catch (error) {
      const { message, data: errorDetails } = error.response.data;
      showToast(message, "error");
      console.error(
        "Error while submitting data:",
        error.response?.data || error.message
      );
    }
  };
  console.log("relationList", relationList);
  return (
    <div className={classes.tablco}>
      {!intitialStep && (
        <div
          style={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            padding: "15px",
            width: "60%",
            margin: "0 auto",
            textAlign: "left",
            borderRadius: "7px",
          }}
        >
          {/* <img src={Thankyou} style={{ width: "300px" }} /> */}
          <p className={classes.thnktt}>EMI DEBIT CONSENT</p>
          <p className={classes.btmthnktt}>{relationList?.data}</p>

          <div style={{ marginTop: "15px" }}>
            <button
              type="button"
              className={classes.Subbtn}
              style={{
                width: "80px",
                background: "#c6131b",
                marginRight: "15px",
              }}
              onClick={() => handleConcent("NO")}
            >
              Reject
            </button>
            <button
              type="button"
              className={classes.Subbtn}
              style={{ width: "80px", background: "#45cb85" }}
              onClick={() => handleConcent("YES")}
            >
              Accept
            </button>
          </div>
        </div>
      )}
      {intitialStep && (
        <AdditionalThirteen
          showGen={showGen}
          setShowGen={setShowGen}
          showRegenButton={showRegenButton}
          setShowRegenButton={setShowRegenButton}
        />
      )}
    </div>
  );
};

export default ThirteenStep;
