import React, { useContext, useEffect, useState } from "react";
import classes from "./Language.module.css";
import { useNavigate } from "react-router-dom";
import AdditionalSeven from "./AdditionalSeven";
import Thankyou from "../../../icons/Thnaks.png";
import AdditionalThirteen from "./AdditionalThirteen";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import { LoanContext } from "../../../context/LoanContext";
import { useToast } from "../../../context/ToastProvder";
import CoAEsign from "./CoAEsign";
import GuaEign from "./GuaEign";
const EsingGen = ({ setActiveStep }) => {
  const [intitialStep, setInitialStep] = useState(false);
  const [showGen, setShowGen] = useState(true);
  const [stpp, setStpp] = useState(null);
  const [showRegenButton, setShowRegenButton] = useState(false);
  const [showGenG, setShowGenG] = useState(true);
  const [showRegenButtonG, setShowRegenButtonG] = useState(false);
  const { borrId, handleNext } = useContext(LoanContext);
  const { showToast } = useToast();
  const [{ data: relationList }] = useQueries({
    queries: [
      {
        queryKey: ["rela"],
        queryFn: async () => {
          const res = await api.get("get-emi-debit-consent-data");
          return res.data;
        },

        initialData: [],
      },
    ],
  });
  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          console.log("Loan Status:", response.data);

          // const { product_id } = response?.data;
          const { step } = response?.data?.step_details;
          setStpp(step);
          if (step === 23) {
            // setInitialStep(true);
            setShowGen(false);
            setShowRegenButton(true);
          }
          if (step === 24) {
            setInitialStep(true);
            // setShowGen(false);
            // setShowRegenButton(true);
          }
          if (step === 25) {
            setInitialStep(true);
            setShowGenG(false);
            setShowRegenButtonG(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  useEffect(() => {
    if (borrId) {
      const fetchBorrowerDetails = async () => {
        try {
          const response = await api.post("admin/get-borrower-details", {
            borrower_id: borrId,
          });

          const borrowerData = response?.data?.data;
          console.log(borrowerData);

          if (borrowerData?.skip_co_applicant) {
            setInitialStep(true);
          }
          if (borrowerData?.skip_co_applicant && borrowerData?.skip_guarantor) {
            handleNext();
          }
        } catch (error) {
          console.error("Error fetching borrower details:", error);
        }
      };

      fetchBorrowerDetails();
    }
  }, [borrId]);
  return (
    <div className={classes.tablco}>
      {stpp !== null && !intitialStep && stpp !== "25" && stpp !== "24" && (
        <CoAEsign
          showGen={showGen}
          setShowGen={setShowGen}
          showRegenButton={showRegenButton}
          setShowRegenButton={setShowRegenButton}
          setInitialStep={setInitialStep}
        />
      )}
      {intitialStep && (
        <GuaEign
          showGen={showGenG}
          setShowGen={setShowGenG}
          showRegenButton={showRegenButtonG}
          setShowRegenButton={setShowRegenButtonG}
        />
      )}
    </div>
  );
};

export default EsingGen;
