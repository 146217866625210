import { LinearProgress } from "@mui/material";
import React from "react";

export default function Loader() {
  return (
    <LinearProgress
      sx={{
        "& .MuiLinearProgress-bar": {
          backgroundColor: "#12468a",
        },
      }}
    />
  );
}
