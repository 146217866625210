import { Outlet, Navigate } from "react-router-dom";
import HeaderBar from "./HeaderBar/HeaderBar";
import classes from "./SiteLayout.module.css";
import { Suspense, lazy, useEffect, useState } from "react";
import SideNav from "./SideNav/SideNav";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import api from "../Common/api";
// import { useDispatch } from "react-redux";
// import { authActions } from "../store/StoreSlices/authSlice";
// import withAuthenticationCheck from "../util/withAuthenticationCheck";
// import Loader from "./SharedUI/Loader";
// const SideNav = lazy(() => import("./sidenav/Sidenav"));
// import HeaderBar from "./headerbar/Header";
// const HeaderBar = lazy(() => import("./headerbar/Header"));

// export default ProtectedRoute;

function SiteLayout() {
  const { user, token, setUser, setToken } = useContext(AuthContext);
  const [hide, setHide] = useState(true);
  const sideNavHideHandler = () => {
    setHide((val) => !val);
  };
  if (!token) {
    return <Navigate to="/login" />;
  }
  // if (!token) {
  //   return <Navigate to="/b2cAdmin/login" />;
  // }
  // useEffect(() => {
  //   console.log("dispatching init");
  //   dispatch(authActions.initializeApp());
  // }, []);
  // if (!auth.isAuthenticated) {
  //   return <Navigate to="/b2cAdmin/login" />;
  // }

  // const token = localStorage.getItem("authToken");
  // console.log("local token", token);
  // console.log("auth token", auth.token);
  // if (!auth.isAuthenticated) {
  //   return <Navigate to="/b2cAdmin/login" />;
  // }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {hide && <SideNav onHide={sideNavHideHandler} />}
      <div>
        <div
          className={`${classes.outletContainer} ${
            hide ? classes.marginLeft : ""
          }`}
        >
          <HeaderBar onHide={sideNavHideHandler} hide={hide} />
          <Outlet />
        </div>
      </div>
    </div>
  );
}
export default SiteLayout;
//export default withAuthenticationCheck(SiteLayout);

//  <>
//       <div>
//         {hide && <SideNav />}
//         {/* <Suspense fallback={<Loader />}>{hide && <SideNav />}</Suspense> */}

//         <div
//           className={`${classes.outletContainer} ${
//             hide ? classes.marginLeft : ""
//           }`}
//         ></div>
//         <HeaderBar onHide={sideNavHideHandler} />
//         <Outlet />
//       </div>
//     </>
