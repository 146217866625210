import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import classes from "./MultiSelectInput.module.css";

const ListWithSearch = ({
  options,
  customPlaceHolder,
  //onInputChange,
  getOptionLabel,
  onChange,
  renderOption,
  //initialValue,
  value,
  className,
}) => {
  const [fieldValue, setFieldValue] = useState(value || {});

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <div style={{ height: "100%" }}>
      <Autocomplete
        value={fieldValue}
        style={{ height: "100%" }}
        options={options}
        // sx={{ width: 300 }}
        classes={{ paper: classes.customAutocompletePaper }}
        className={className}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        filterOptions={(options, state) => {
          const inputValue = state.inputValue.toLowerCase();
          return options.filter((option) =>
            option.name.toLowerCase().includes(inputValue)
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            // placeholder=" Enter Employee Name"
            // placeholder={customPlaceHolder || "Enter Employee Name"}
            variant="outlined"
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                paddingRight: "15px",
              },

              "& .MuiOutlinedInput-root": {
                padding: "unset",
                height: "100%",
                paddingRight: "5px !important",
                paddingLeft: "5px !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#dadada !important",
                borderWidth: "1px !important",
              },
              "& .MuiAutocomplete-input": {
                padding: "unset !important",
                // height: "100%",
              },
              "& .MuiOutlinedInput-input": {
                boxSizing: "border-box",
                padding: "unset",
                fontSize: "11px",
                height: "max-content",
              },
              "& .MuiChip-root": {
                fontSize: "11px",
                height: "25px",
              },
              "& .MuiChip-deleteIcon": {
                fontSize: "5px",
              },
              "& .MuiAutocomplete-clearIndicator": {
                fontSize: "5px",
                color: "white",
              },
              "& .MuiAutocomplete-clearIndicator:hover": {
                background: "rgb(105, 105, 105)",
              },
              "& .MuiAutocomplete-endAdornment": {
                right: "3px",
                // top: "calc(50% - 12px)",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "11px",
              },
              height: "100%",
            }}
          />
        )}
      />
    </div>
  );
};

export default ListWithSearch;
