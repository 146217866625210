import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import SelectModal from "./SelectModal";
import classes from "./Language.module.css";
import BorrowerFamily from "./BorrowerFamily";
import BorrowerOccupation from "./BorrowerOccupation";
import { LoanContext } from "../../../context/LoanContext";
import api from "../../Common/api";
const StepFour = () => {
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { borrId, userD } = useContext(LoanContext);
  const [familyStep, setFamilyStep, setDataFromAdhar] = useState(false);
  // useEffect(() => {
  //   if (!userD && borrId) {
  //     const fetchBorrowerDetails = async () => {
  //       try {
  //         const response = await api.post("admin/get-user-address-details", {
  //           borrower_id: borrId,
  //           user_type: "BORROWER",
  //         });
  //         console.log(response.data.data);
  //         setDataFromAdhar(response.data.data);
  //       } catch (error) {
  //         console.error("Error fetching borrower details:", error);
  //       }
  //     };

  //     fetchBorrowerDetails();
  //   }
  // }, [userD, borrId]);
  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          console.log("Loan Status:", response.data);

          // const { product_id } = response?.data;
          const { step } = response?.data?.step_details;

          if (step === 3) {
            setFamilyStep(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  return (
    <div className={classes.tablco} style={{ borderBottom: "1px solid #ddd" }}>
      {!familyStep && <BorrowerFamily setFamilyStep={setFamilyStep} />}
      {familyStep && <BorrowerOccupation />}
    </div>
  );
};

export default StepFour;
