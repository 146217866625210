import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
import PropTypes from "prop-types";
import { Input as BaseInput } from "@mui/base/Input";
import { Box, styled } from "@mui/system";

import { useNavigate } from "react-router-dom";
import { useToast } from "../../../context/ToastProvder";
import OtpInput from "../VarifyItsYou/OtpInput";
const AddModal = ({ addHandle, defaultValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const [Uerr, setUErr] = useState(null);
  const [err, setErr] = useState(null);
  const { showToast } = useToast();
  console.log("Uerr", Uerr);
  const onSubmit = async (data) => {
    let otpValue = Object.values(data.otp).join(""); // Concatenate OTP values
    console.log("OTP Entered:", otpValue);
    let payload = {
      otp: otpValue,
    };
    console.log("updatedData", payload);
    // updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url: "admin/account-verfication-verify-otp",
    method: "post",
    invalidateKey: "userlist",
    setErr,
    successCallback: () => {
      reset();
      addHandle();
    },
    onError: (error) => {
      console.error("Login error", error.response.data);
      const { message, data: errorDetails } = error.response.data;
      // console.error("Login data", error.response.data);
      showToast(message, "error");
      // showToast(err?.error_list?., "error");
    },
  });
  console.log({ err });
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      style={{ width: "340px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {defaultValue ? "Enter OTP" : "Enter OTP"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <OtpInput register={register} name="otp" length={6} />{" "}
          {/* <span className={styles.error}>{err.otp ? err.otp[0] : ""}</span> */}
          {/* Use the OTP Input component here */}
          {/* <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Name
            </lebel>
            <input
              {...register("name", { required: true })}
              type="text"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.name && (
              <span className={styles.error}>This Field is Required.</span>
            )}
            <span className={styles.error}>{err.name ? err.name[0] : ""}</span>
          </div> */}
        </div>
      </div>

      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddModal;
