import React, { useState } from "react";
import styles from "./Table.module.css";
import { FaEye, FaSort } from "react-icons/fa";
import ViewCharges from "./ViewCharges";
import {
  MdDelete,
  MdOutlineAddCircleOutline,
  MdPlaylistAddCheckCircle,
} from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import { useNavigate } from "react-router-dom";
import AddCharges from "./AddCharges";
import CustomActInBox from "../../Common/Custom Confirm/CustomActInBox";
import useCustomMutation from "../../CustomHook/useCustomMutation";
const Table = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [char, setChar] = useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [viewChar, setViewChar] = useState(false);
  const ViewCharHandle = () => {
    setViewChar((val) => !val);
  };
  const handleViewCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setViewChar((val) => !val);
  };
  const addCharHandle = () => {
    setChar((val) => !val);
  };
  const handleCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setChar((val) => !val);
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  console.log("rowData", rowData);
  const navigate = useNavigate();

  const handleEdit = (val) => {
    console.log("val", val);
    navigate("/edit-dealer", {
      state: { val },
    });
  };

  const handleView = (val) => {
    console.log("val", val);
    navigate("/dealer-authority", {
      state: { val },
    });
  };
  const updateDelMutation = useCustomMutation({
    url: `admin/update-dealer-status/${selectedItem?.id}`,
    method: "get",
    invalidateKey: "langList",
    // setErr: setErr,
    successCallback: () => {
      // reset();
      // addHandle();
    },
  });
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteHandler = (data) => {
    setSelectedItem(data);
    handleClickOpen();
    console.log(data);
  };

  const deleteConfirmHandler = () => {
    console.log("selectedData", selectedItem);
    updateDelMutation.mutate(selectedItem);
    handleClose();
  };
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      {" "}
      {open && (
        <CustomActInBox
          data={selectedItem}
          onCancel={handleClose}
          onConfirm={deleteConfirmHandler}
          message={`Are you sure you want to ${
            selectedItem.is_active ? "deactivate" : "activate"
          } ${selectedItem.name}`}
          open={open}
        />
      )}
      <div className={styles.tabwrap}>
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                background: "linear-gradient(to bottom, #12468a, #235392)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th>#</th>
                <th
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Dealers Name</span>
                  <FaSort />
                </th>
                <th> Dealer ID</th>
                <th> Email</th>
                <th> Mobile</th>
                {perm?.includes("update") && (
                  <th style={{ maxWidth: "90px" }}> Status</th>
                )}

                <th> Action</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr key={row.id}>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td>{row.name}</td>
                  <td>{row.dealer_unq}</td>
                  <td>{row.email}</td>
                  <td>{row.mobile}</td>
                  {perm?.includes("update") && (
                    <td style={{ maxWidth: "90px" }}>
                      {row?.is_active === true ? (
                        <button
                          title="Inactivate Status"
                          type="button"
                          onClick={() => deleteHandler(row)}
                          style={{ minWidth: "70px" }}
                          className={styles.accessbtn}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          type="button"
                          title="Activate Status"
                          onClick={() => deleteHandler(row)}
                          className={styles.accessbtnnegativ}
                          style={{ minWidth: "70px" }}
                        >
                          Inactive
                        </button>
                      )}
                    </td>
                  )}

                  <td>
                    {" "}
                    {perm?.includes("update") && (
                      <button
                        type="button"
                        onClick={() => handleEdit(row)}
                        className={styles.editbtn}
                      >
                        <AiFillEdit
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                    {perm?.includes("update") && (
                      <button
                        title="Product Charges"
                        type="button"
                        style={{
                          background: "orange",
                        }}
                        onClick={() => handleCharEditRow(row)}
                        className={styles.editbtn}
                      >
                        <MdOutlineAddCircleOutline
                          size={12}
                          style={{
                            position: "relative",
                            top: "1px",
                          }}
                        />
                      </button>
                    )}
                    <button
                      type="button"
                      title="View Product Charges"
                      style={{
                        background: "#FFD700",
                      }}
                      onClick={() => handleViewCharEditRow(row)}
                      className={styles.editbtn}
                    >
                      <MdPlaylistAddCheckCircle
                        size={12}
                        style={{
                          position: "relative",
                          top: "1px",
                        }}
                      />
                    </button>
                    <button
                      type="button"
                      className={styles.dltbtn}
                      style={{ background: "orange", marginRight: "7px" }}
                      onClick={() => handleView(row)}
                    >
                      <FaEye
                        size={12}
                        style={{ position: "relative", top: "1px" }}
                      />
                    </button>
                    {perm?.includes("delete") && (
                      <button type="button" className={styles.dltbtn}>
                        <MdDelete
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AddModal addHandle={addHandle} defaultValue={rowToEdit || []} />
        </Backdrop>
        {char && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={char}
            onClick={addCharHandle}
          >
            <AddCharges
              addHandle={addCharHandle}
              defaultValue={rowToEdit || []}
            />
          </Backdrop>
        )}
        {viewChar && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={viewChar}
            onClick={ViewCharHandle}
          >
            <ViewCharges
              addHandle={ViewCharHandle}
              defaultValue={rowToEdit || []}
            />
          </Backdrop>
        )}
      </div>
    </div>
  );
};

export default Table;
