import React, { useContext, useState } from "react";
import classes from "./Dashboard.module.css";
import SearchDashboard from "./SearchDashboard";
import MonthBtn from "./MonthBtn";
import TotalDash from "./TotalDash";
// import BottomDetails from "./BottomDetails";
import DashChart from "./DashChart";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../Common/api";
import YearTotalDash from "./YearTotalDash";
import { AuthContext } from "../../../context/AuthContext";

const LenderDashboard = () => {
  const [searchData, setSearchData] = useState(null);

  const [selectedMonth, setSelectedMonth] = useState("");
  const fetchData = async (searchData, selectedMonth) => {
    const payload = { month: selectedMonth };

    if (searchData) {
      payload.bankId = searchData.bankId || "";
    }

    const response = await api.get("get-dashboard-details", {
      params: payload,
    });
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList", searchData, selectedMonth],
    queryFn: () => fetchData(searchData, selectedMonth),
    onSuccess: (data) => {},
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  const [perm, setPerm] = useState([]);
  const fetchPermissions = async () => {
    const response = await api.get("admin/get-sub-menu-permissions/12");
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions"],
    queryFn: fetchPermissions,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });

  console.log("perm", perm);
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <div className={classes["main-body"]}>
          <SearchDashboard />
          <YearTotalDash perm={perm} />
          <MonthBtn
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            perm={perm}
          />
          <TotalDash
            dashboardList={dashboardList}
            selectedMonth={selectedMonth}
            perm={perm}
          />
          <DashChart
            dashboardList={dashboardList}
            selectedMonth={selectedMonth}
          />
          {/* <BottomDetails /> */}
        </div>
      </div>
    </div>
  );
};

export default LenderDashboard;
