import React, { useContext, useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import sampleimg from "./../../../icons/sampleimg.png";
import ZoomImg from "./ZoomImg";
import { Backdrop } from "@mui/material";
import { AuthContext } from "../../../context/AuthContext";
const GuaModal = ({ addHandle, defaultValue, borrowerId, doc, type }) => {
  const { user, token, setUser, setToken, baseURL, baseDoc } =
    useContext(AuthContext);
  const [bimg, setBimg] = useState(false);
  const [zoomImgUrl, setZoomImgUrl] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const imgHandle = (url) => {
    setZoomImgUrl(url);
    setBimg((val) => !val);
  };
  const getFullUrl = (path) => (path ? `${baseDoc}${path}` : null);

  const renderImages = (imagePaths) => {
    return imagePaths
      .split(",")
      .map((path, index) => (
        <img
          key={index}
          src={getFullUrl(path)}
          style={{ width: "150px", height: "100px" }}
          alt={`Signed Cheque ${index + 1}`}
          onClick={() => imgHandle(getFullUrl(path))}
        />
      ));
  };
  return (
    <form
      style={{ minWidth: "400px" }}
      className={styles.modalcontainerr}
      onClick={(event) => event.stopPropagation()}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={bimg}
        onClick={imgHandle}
      >
        <ZoomImg
          addHandle={imgHandle}
          zoomImgUrl={zoomImgUrl}
          borrowerId={borrowerId}
        />
      </Backdrop>
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          Documents{" "}
          {type === "borrower"
            ? "of Borrower"
            : type === "coapplicant"
            ? "of Co-Applicant"
            : "Of Guarantor"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red", display: "flex", gap: "10px" }}
      >
        <div className={styles.modalform}>
          {type === "guarantor" && doc.guarantor_aadhar_front && (
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Aadhar Card
            </p>
          )}

          <div style={{ display: "flex", gap: "10px" }}>
            {type === "guarantor" && doc.guarantor_aadhar_front && (
              <img
                src={getFullUrl(doc.guarantor_aadhar_front)}
                style={{ width: "150px", height: "100px" }}
                alt="Aadhar Front"
                onClick={() =>
                  imgHandle(getFullUrl(doc?.guarantor_aadhar_front))
                }
              />
            )}
            {type === "guarantor" && doc.guarantor_aadhar_back && (
              <img
                src={getFullUrl(doc.guarantor_aadhar_back)}
                style={{ width: "150px", height: "100px" }}
                alt="Aadhar Back"
                onClick={() =>
                  imgHandle(getFullUrl(doc?.guarantor_aadhar_back))
                }
              />
            )}
          </div>
        </div>
        {type === "guarantor" && doc.guarantor_avtar && (
          <div className={styles.modalform}>
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Image
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
              {" "}
              <img
                src={getFullUrl(doc.guarantor_avtar)}
                style={{ width: "150px", height: "100px" }}
                alt="Image"
                onClick={() => imgHandle(getFullUrl(doc?.guarantor_avtar))}
              />
            </div>
          </div>
        )}
        <div className={styles.modalform}>
          {type === "guarantor" && doc.guarantor_peraddress_proof && (
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Address Proof
            </p>
          )}

          <div style={{ display: "flex", gap: "10px" }}>
            {" "}
            {type === "guarantor" && doc.guarantor_peraddress_proof && (
              <img
                src={getFullUrl(doc.guarantor_peraddress_proof)}
                style={{ width: "150px", height: "100px" }}
                alt="Address Proof"
                onClick={() =>
                  imgHandle(getFullUrl(doc?.guarantor_peraddress_proof))
                }
              />
            )}
            {type === "guarantor" && doc.guarantor_coresaddress_proof && (
              <img
                src={getFullUrl(doc.guarantor_coresaddress_proof)}
                style={{
                  width: "150px",
                  height: "100px",
                  position: "relative",
                  top: "23.5px",
                }}
                alt="Address Proof"
                onClick={() =>
                  imgHandle(getFullUrl(doc?.guarantor_coresaddress_proof))
                }
              />
            )}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px" }}>
        {type === "guarantor" && doc.guarantor_pan_doc && (
          <div
            className={styles.modalform}
            style={{ marginTop: "10px", padding: "10px" }}
          >
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              PAN Card
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
              <img
                src={getFullUrl(doc.guarantor_pan_doc)}
                style={{ width: "150px", height: "100px" }}
                alt="PAN Card"
                onClick={() => imgHandle(getFullUrl(doc?.guarantor_pan_doc))}
              />
            </div>
          </div>
        )}
        {type === "guarantor" && doc.guarantor_driving_license && (
          <div
            className={styles.modalform}
            style={{ marginTop: "10px", padding: "10px" }}
          >
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Driving Licence
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
              <img
                src={getFullUrl(doc.guarantor_driving_license)}
                style={{ width: "150px", height: "100px" }}
                alt="PAN Card"
                onClick={() =>
                  imgHandle(getFullUrl(doc?.guarantor_driving_license))
                }
              />
            </div>
          </div>
        )}
      </div>

      {/* <div style={{ padding: "10px 15px" }}>
        <div style={{ height: "450px", overflowY: "scroll" }}>
          <table>
            <tbody>
              <tr>
                <td>
                  {" "}
                  <p className={styles.lblodp}>Aadhar Front</p>
                  {type === "guarantor" && doc.guarantor_avtar && (
                    <img
                      src={getFullUrl(doc.guarantor_avtar)}
                      style={{ width: "150px", height: "100px" }}
                      alt="Aadhar Front"
                      onClick={() =>
                        imgHandle(getFullUrl(doc?.guarantor_avtar))
                      }
                    />
                  )}
                </td>
                <td>
                  <p className={styles.lblodp}>Replace Aadhar Front</p>
                  <input
                    type="file"
                    style={{ width: "250px" }}
                    className={styles.uploadbx}
                    {...register("guarantor_avtar")}
                  />
                </td>
                <td>
                  <p className={styles.lblodp}>Remark</p>
                  <input
                    type="text"
                    style={{ width: "250px" }}
                    className={styles.inpttsc}
                    {...register("guarantor_avtar_remark")}
                  />
                </td>
              </tr>{" "}
              <tr>
                <td>
                  {" "}
                  <p className={styles.lblodp}>Aadhar Front</p>
                  {type === "guarantor" && doc.guarantor_aadhar_front && (
                    <img
                      src={getFullUrl(doc.guarantor_aadhar_front)}
                      style={{ width: "150px", height: "100px" }}
                      alt="Aadhar Front"
                      onClick={() =>
                        imgHandle(getFullUrl(doc?.guarantor_aadhar_front))
                      }
                    />
                  )}
                </td>
                <td>
                  <p className={styles.lblodp}>Replace Aadhar Front</p>
                  <input
                    type="file"
                    style={{ width: "250px" }}
                    className={styles.uploadbx}
                    {...register("guarantor_aadhar_front")}
                  />
                </td>
                <td>
                  <p className={styles.lblodp}>Remark</p>
                  <input
                    type="text"
                    style={{ width: "250px" }}
                    className={styles.inpttsc}
                    {...register("guarantor_aadhar_front_remark")}
                  />
                </td>
              </tr>{" "}
              <tr>
                <td>
                  <p className={styles.lblodp}>Aadhar Back</p>
                  {type === "guarantor" && doc.guarantor_aadhar_back && (
                    <img
                      src={getFullUrl(doc.guarantor_aadhar_back)}
                      style={{ width: "150px", height: "100px" }}
                      alt="Aadhar Back"
                      onClick={() =>
                        imgHandle(getFullUrl(doc?.guarantor_aadhar_back))
                      }
                    />
                  )}
                </td>
                <td>
                  <p className={styles.lblodp}>Replace Aadhar Back</p>
                  <input
                    type="file"
                    style={{ width: "250px" }}
                    className={styles.uploadbx}
                    {...register("guarantor_aadhar_back")}
                  />
                </td>
                <td>
                  <p className={styles.lblodp}>Remark</p>
                  <input
                    type="text"
                    style={{ width: "250px" }}
                    className={styles.inpttsc}
                    {...register("guarantor_aadhar_back_remark")}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.lblodp}>PAN Card</p>
                  {type === "guarantor" && doc.guarantor_pan_doc && (
                    <img
                      src={getFullUrl(doc.guarantor_pan_doc)}
                      style={{ width: "150px", height: "100px" }}
                      alt="PAN Card"
                      onClick={() =>
                        imgHandle(getFullUrl(doc?.guarantor_pan_doc))
                      }
                    />
                  )}
                </td>
                <td>
                  <p className={styles.lblodp}>Replace PAN Card</p>
                  <input
                    type="file"
                    style={{ width: "250px" }}
                    className={styles.uploadbx}
                    {...register("guarantor_pan_doc")}
                  />
                </td>
                <td>
                  <p className={styles.lblodp}>Remark</p>
                  <input
                    type="text"
                    style={{ width: "250px" }}
                    className={styles.inpttsc}
                    {...register("guarantor_pan_doc_remark")}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.lblodp}>Driving License</p>
                  {type === "guarantor" && doc.guarantor_driving_license && (
                    <img
                      src={getFullUrl(doc.guarantor_driving_license)}
                      style={{ width: "150px", height: "100px" }}
                      alt="Driving License"
                      onClick={() =>
                        imgHandle(getFullUrl(doc?.guarantor_driving_license))
                      }
                    />
                  )}
                </td>
                <td>
                  <p className={styles.lblodp}>Replace Driving License</p>
                  <input
                    type="file"
                    style={{ width: "250px" }}
                    className={styles.uploadbx}
                    {...register("guarantor_driving_license")}
                  />
                </td>
                <td>
                  <p className={styles.lblodp}>Remark</p>
                  <input
                    type="text"
                    style={{ width: "250px" }}
                    className={styles.inpttsc}
                    {...register("guarantor_driving_license_remark")}
                  />
                </td>
              </tr>{" "}
              <tr>
                <td>
                  <p className={styles.lblodp}>Address Proof</p>
                  {type === "guarantor" && doc.guarantor_coresaddress_proof && (
                    <img
                      src={getFullUrl(doc.guarantor_coresaddress_proof)}
                      style={{ width: "150px", height: "100px" }}
                      alt="Electricity Bill"
                      onClick={() =>
                        imgHandle(getFullUrl(doc?.guarantor_coresaddress_proof))
                      }
                    />
                  )}
                </td>
                <td>
                  <p className={styles.lblodp}>Replace Address Proof</p>
                  <input
                    type="file"
                    style={{ width: "250px" }}
                    className={styles.uploadbx}
                    {...register("guarantor_coresaddress_proof")}
                  />
                </td>
                <td>
                  <p className={styles.lblodp}>Remark</p>
                  <input
                    type="text"
                    style={{ width: "250px" }}
                    className={styles.inpttsc}
                    {...register("guarantor_coresaddress_proof_remark")}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
      {/* <div style={{ display: "flex", gap: "10px", margin: "10px" }}>
        <button type="submit" className={styles.Subbtn}>
          Submit
        </button>
      </div> */}
    </form>
  );
};

export default GuaModal;
