import React, { useEffect, useState } from "react";
import styles from "./AddChar.module.css";
import { useForm, Controller } from "react-hook-form";
import close from "./../../../icons/close.png";
import { useToast } from "../../../context/ToastProvder";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQueries, useQuery } from "@tanstack/react-query";
import api from "../../Common/api";

const AddCharges = ({ addHandle, defaultValue }) => {
  const [tkn, setTkn] = useState(null);
  const { showToast } = useToast();
  const [{ data: lenderList }] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () => api.get("admin/lender-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  const defaultCharges = [
    {
      charge_head: "Processing Fee",
      charge_value: "0",
      finayo_share: "0",
      nbfc_share: "0",
    },
    {
      charge_head: "Subvention Amount",
      charge_value: "5000",
      finayo_share: "5000",
      nbfc_share: "0",
    },
    {
      charge_head: "FLDG",
      charge_value: "5000",
      finayo_share: "0",
      nbfc_share: "5000",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: { charges: defaultCharges },
  });

  const fetchPermissions = async () => {
    const response = await api.get(
      `admin/get-active-charges-by-lender/${defaultValue.id}`
    );
    return response.data;
  };

  const { data: permissionsData, isSuccess } = useQuery({
    queryKey: ["editData", defaultValue],
    queryFn: fetchPermissions,
    onSuccess: (data) => {
      console.log("data in map", data);
      if (data?.status === "success" && data.data.length > 0) {
        const charges = data?.data?.map((charge) => ({
          charge_head: charge.charge_head,
          charge_value: charge.charge_value || "",
          finayo_share: charge.finayo_share || "",
          nbfc_share: charge.nbfc_share || "",
        }));
        reset({ charges });
        if (data.data.length > 0 && data.data[0].token_id) {
          setTkn(data.data[0].token_id);
        }
        if (data.data.length > 0 && data.data[0].lender_id) {
          setValue("lender_id", data.data[0].lender_id);
        }
      } else {
        reset({ charges: defaultCharges });
      }
    },
    onError: () => {
      // reset({ charges: defaultCharges });
    },
  });
  useEffect(() => {
    if (defaultValue?.id) {
      reset({ charges: defaultCharges });
    }
  }, [addHandle]);
  // useEffect(() => {
  //   if (defaultValue?.id) {
  //     // Trigger the fetch and reset when `defaultValue` changes
  //     fetchPermissions();
  //   }
  // }, [defaultValue]);
  const validateCharges = (charges) => {
    let isValid = true;
    charges.forEach((charge, index) => {
      if (
        parseFloat(charge.finayo_share) + parseFloat(charge.nbfc_share) >
        parseFloat(charge.charge_value)
      ) {
        isValid = false;
        showToast(
          "Sum of Finayo Share and NBFC Share must not exceed Charge Value."
        );
        setError(`charges[${index}].finayo_share`, {
          type: "manual",
          message:
            "Sum of Finayo Share and NBFC Share must not exceed Charge Value.",
        });
        setError(`charges[${index}].nbfc_share`, {
          type: "manual",
          message:
            "Sum of Finayo Share and NBFC Share must not exceed Charge Value.",
        });
      } else {
        clearErrors(`charges[${index}].finayo_share`);
        clearErrors(`charges[${index}].nbfc_share`);
      }
    });
    return isValid;
  };
  const onSubmit = (data) => {
    if (!validateCharges(data.charges)) {
      return;
    }

    const payload = {
      lender_id: defaultValue?.id,
      charge_details: data.charges.map((charge) => ({
        charge_head: charge.charge_head,
        charge_value: charge.charge_value,
        finayo_share: charge.finayo_share,
        nbfc_share: charge.nbfc_share,
      })),
    };
    if (tkn) {
      payload.token_id = tkn;
    }

    console.log("Payload:", payload);
    updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url: "admin/store-lender-charges",
    method: "post",
    invalidateKey: "editData",
    successCallback: () => {
      reset({ charges: defaultCharges });
      addHandle();
    },
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      style={{ width: "740px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {defaultValue ? "Product Charges" : "Product Charges"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBottom: "1px solid #red" }}
      >
        {/* <div style={{ textAlign: "left", marginBottom: "12.5px" }}>
          <label
            style={{
              color: "#495057",
              fontWeight: "bold",
              fontSize: "13px",
              marginRight: "5px",
            }}
          >
            Lender :
          </label>
          <select
            {...register("lender_id", { required: true })}
            style={{
              borderRadius: "4px",
              height: "25px",
              border: "1px solid #ddd",
              width: "200px",
            }}
          >
            <option value="">--Select Lender--</option>
            {lenderList?.data?.map((row, index) => (
              <option value={row.id}>{row.name}</option>
            ))}
          </select>
          {errors.lender_id && (
            <span className={styles.error}>This Field is Missing.</span>
          )}
        </div> */}
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    color: "#495057",
                    fontWeight: "bold",
                    padding: "5px",
                  }}
                >
                  Charges Head
                </th>
                <th
                  style={{
                    color: "#495057",
                    fontWeight: "bold",
                    padding: "5px",
                  }}
                >
                  Total Charge Value
                </th>
                <th
                  style={{
                    color: "#495057",
                    fontWeight: "bold",
                    padding: "5px",
                  }}
                >
                  Finayo Share
                </th>
                <th
                  style={{
                    color: "#495057",
                    fontWeight: "bold",
                    padding: "5px",
                  }}
                >
                  NBFC Share
                </th>
              </tr>
            </thead>
            <tbody>
              {control._defaultValues.charges.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      style={{ borderRadius: "4px", height: "20px" }}
                      type="text"
                      value={item.charge_head}
                      disabled
                    />
                  </td>

                  <td>
                    <Controller
                      name={`charges[${index}].charge_value`}
                      control={control}
                      render={({ field }) => (
                        <input
                          type="number"
                          {...field}
                          min="0"
                          step="0.01"
                          style={{
                            borderRadius: "4px",
                            height: "20px",
                            border: "1px solid #ddd",
                          }}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Controller
                      name={`charges[${index}].finayo_share`}
                      control={control}
                      render={({ field }) => (
                        <input
                          type="number"
                          {...field}
                          min="0"
                          step="0.01"
                          style={{
                            borderRadius: "4px",
                            height: "20px",
                            border: "1px solid #ddd",
                          }}
                        />
                      )}
                    />
                    {/* {errors.charges?.[index]?.finayo_share && (
                      <span className={styles.error}>
                        {errors.charges[index].finayo_share.message}
                      </span>
                    )} */}
                  </td>
                  <td>
                    <Controller
                      name={`charges[${index}].nbfc_share`}
                      control={control}
                      render={({ field }) => (
                        <input
                          type="number"
                          {...field}
                          step="0.01"
                          min="0"
                          style={{
                            borderRadius: "4px",
                            height: "20px",
                            border: "1px solid #ddd",
                          }}
                        />
                      )}
                    />
                    {/* {errors.charges?.[index]?.nbfc_share && (
                      <span className={styles.error}>
                        {errors.charges[index].nbfc_share.message}
                      </span>
                    )} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddCharges;
