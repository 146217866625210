import React, { useState } from "react";
import styles from "./Table.module.css";
import { FaSort } from "react-icons/fa";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import { useNavigate } from "react-router-dom";
const Table = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const navigate = useNavigate();
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  // const handleAccess = (id) => {
  //   navigate(`/access-permission`);
  // };
  // const handleAccess = (val) => {
  //   // console.log("val", val);
  //   navigate("/b2cAdmin/access-permission", {
  //     state: { val },
  //   });
  // };
  const handleAccess = (val) => {
    // console.log("val", val);
    navigate("/access-permission", {
      state: { val },
    });
  };
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      <div className={styles.tabwrap}>
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                background: "linear-gradient(to bottom, #12468a, #235392)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th>#</th>
                <th
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span> Role Name</span>
                  <FaSort />
                </th>
                <th> Access Permission</th>
                {/* <th> Action</th> */}
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  {/* <td>{row.role}</td> */}
                  <td>{row.user_type}</td>
                  <td>
                    {perm?.includes("update") && (
                      <button
                        type="button"
                        onClick={() => handleAccess(row.id)}
                        className={styles.accessbtn}
                      >
                        Access
                      </button>
                    )}
                  </td>
                  {/* <td>
                  <button className={styles.editbtn}  type="button">
                    <AiFillEdit
                      size={12}
                      style={{ position: "relative", top: "1px" }}
                    />
                  </button>
                  <button className={styles.dltbtn}  type="button">
                    <MdDelete
                      size={12}
                      style={{ position: "relative", top: "1px" }}
                    />
                  </button>
                </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AddModal
            addHandle={addHandle}
            defaultValue={rowToEdit !== null && rowData[rowToEdit]}
          />
        </Backdrop>
      </div>
    </div>
  );
};

export default Table;
