import React from "react";
import classes from "./Language.module.css";
import { useForm } from "react-hook-form";
const OtherInputs = () => {
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  return (
    <div
      style={{
        marginTop: "10px",
        borderTop: "1px solid #ddd",
        padding: "15px 0",
      }}
    >
      {" "}
      <div className={classes.totalcheckconnn}>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Full Name<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("name", { required: true })}
            type="text"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            DOB<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("dob", { required: true })}
            type="date"
            className={classes.inputstst}
          />

          {errors.dob && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>{" "}
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Driving Licence Number<span className={classes.requ}>*</span>
          </label>
          <div style={{ display: "flex", width: "100%" }}>
            <input
              // placeholder="Enter Name"
              {...register("name", { required: true })}
              type="text"
              style={{ borderRadius: " 5px 0 0 5px ", width: "300px" }}
              className={classes.inputstst}
            />
            <button
              type="button"
              style={{
                background: "#45cb85",
                border: "none",
                color: "#fff",
                borderRadius: "  0 5px 5px 0  ",
                fontSize: "11px",
              }}
            >
              Verify
            </button>
          </div>
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Email Id<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="email"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Martial Status<span className={classes.requ}>*</span>
          </label>

          <select
            {...register("hjreh", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option>--Select Martial Status--</option>
          </select>
          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Husbend / Wife Name<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="text"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Father's Name<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="text"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      >
        {/* {!defaultValue && ( */}
        <button
          type="submit"
          className={classes.Subbtn}
          style={{ width: "80px", background: "#45cb85" }}
        >
          Submit
        </button>
        {/* )} */}
      </div>
    </div>
  );
};

export default OtherInputs;
