import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Language.module.css";
import AdditionalTen from "./AdditionalTen";
import { AuthContext } from "../../../context/AuthContext";
import { useToast } from "../../../context/ToastProvder";
import { LoanContext } from "../../../context/LoanContext";
import api from "../../Common/api";
import axios from "axios";
import { useQueries, useQuery } from "@tanstack/react-query";
const StepTen = () => {
  const { borrId } = useContext(LoanContext);
  const [intitialStep, setInitialStep] = useState(false);
  const [relData, setRelData] = useState([]);
  const { showToast } = useToast();
  const { token, baseURL, baseDoc } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [proId, setProductId] = useState(null);
  const HandleShow = () => {
    setShow(true);
  };
  const [{ data: relatioList }] = useQueries({
    queries: [
      {
        queryKey: ["rela"],
        queryFn: async () => {
          // const payload = { product_id: product?.product_id };
          const res = await api.get(`get-relations`);
          return res?.data;
        },

        initialData: { data: [] },
        onSuccess: (data) => {
          setRelData(data); // Update the relData state with the response data
        },
      },
    ],
  });
  console.log("relatioList", relatioList);
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          console.log("Loan Status:", response.data);

          const { product_id, step_details } = response?.data;
          const { step } = step_details;

          setProductId(product_id);

          if (step === 14) {
            setInitialStep(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  const onSubmit = async (data) => {
    if (data.name_1 === data.name_2) {
      showToast(
        "Reference 1 and Reference 2 details cannot be the same.",
        "error"
      );
      return;
    }
    if (data.mobile_1 === data.mobile_2) {
      showToast(
        "Reference 1 and Reference 2 details cannot be the same.",
        "error"
      );
      return;
    }

    const formData = new FormData();
    formData.append("borrower_id", borrId);
    formData.append("name_1", data.name_1);
    formData.append("name_2", data.name_2);
    formData.append("mobile_1", data.mobile_1);
    formData.append("mobile_2", data.mobile_2);
    formData.append("relation_2", data.relation_2);
    formData.append("relation_1", data.relation_1);
    formData.append("address_1", data.address_1);
    formData.append("address_2", data.address_2);
    try {
      const apiEndpoint = `${baseURL}admin/store-reference-details`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      console.log("Form submitted successfully:", response.data);
      setInitialStep(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      const { message, errors } = error.response.data;
      console.log("message", message);
      showToast(message, "error");
    }
  };
  return (
    <div>
      {!intitialStep && (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.tablco}>
          <p className={classes.applheader}>REFERENCE 1</p>
          <div className={classes.totalcheckconnn}>
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Name<span className={classes.requ}>*</span>
              </label>
              <input
                // placeholder="Enter Name"
                {...register("name_1", {
                  required: true,
                  validate: (value) =>
                    /^[A-Za-z\s]+$/.test(value) ||
                    "Only letters and spaces are allowed.",
                })}
                type="text"
                className={classes.inputstst}
              />

              {errors.name_1 && (
                <span className={classes.error}>
                  {errors.name_1.message || "This Field is Missing."}
                </span>
              )}
            </div>

            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Mobile Number<span className={classes.requ}>*</span>
              </label>
              <input
                // placeholder="Enter Mobile Number"
                {...register("mobile_1", { required: true })}
                type="number"
                className={classes.inputstst}
              />

              {errors.mobile_1 && (
                <span className={classes.error}>This Field is Missing.</span>
              )}
            </div>
            {/* <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Relation<span className={classes.requ}>*</span>
              </label>
              <input
                // placeholder="Enter Mobile Number"
                {...register("relation_1", { required: true })}
                type="text"
                className={classes.inputstst}
              />

              {errors.relation_1 && (
                <span className={classes.error}>This Field is Missing.</span>
              )}
            </div> */}
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Relation<span className={classes.requ}>*</span>
              </label>

              <select
                {...register("relation_1", { required: true })}
                className={classes.inputstst}
                style={{ height: "29px" }}
              >
                <option value="">--Select Relation with Borrower--</option>
                {relData?.data?.map((row) => (
                  <option value={row}>{row}</option>
                ))}
              </select>
              {errors.relation_1 && (
                <span className={classes.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Address<span className={classes.requ}>*</span>
              </label>
              <input
                {...register("address_1", { required: true })}
                type="text"
                className={classes.inputstst}
              />

              {errors.address_1 && (
                <span className={classes.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <hr
            style={{
              border: "0.5px solid #ddd",
              margin: "15px 0",
              marginTop: "20px",
            }}
          />
          {proId !== "4" && <p className={classes.applheader}>REFERENCE 2</p>}
          <div className={classes.totalcheckconnn}>
            {proId !== "4" && (
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Name<span className={classes.requ}>*</span>
                </label>
                <input
                  {...register("name_2", {
                    required: true,
                    validate: (value) =>
                      /^[A-Za-z\s]+$/.test(value) ||
                      "Only letters and spaces are allowed.",
                  })}
                  type="text"
                  className={classes.inputstst}
                />

                {errors.name_2 && (
                  <span className={classes.error}>
                    {errors.name_2.message || "This Field is Missing."}
                  </span>
                )}
              </div>
            )}
            {proId !== "4" && (
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Mobile Number<span className={classes.requ}>*</span>
                </label>
                <input
                  // placeholder="Enter Mobile Number"
                  {...register("mobile_2", { required: true })}
                  type="number"
                  className={classes.inputstst}
                />

                {errors.mobile_2 && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
            )}
            {proId !== "4" && (
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Relation<span className={classes.requ}>*</span>
                </label>

                <select
                  {...register("relation_2", { required: true })}
                  className={classes.inputstst}
                  style={{ height: "29px" }}
                >
                  <option value="">--Select Relation with Borrower--</option>
                  {relData?.data?.map((row) => (
                    <option value={row}>{row}</option>
                  ))}
                </select>
                {errors.relation_2 && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
            )}
            {proId !== "4" && (
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Address<span className={classes.requ}>*</span>
                </label>
                <input
                  {...register("address_2", { required: true })}
                  type="text"
                  className={classes.inputstst}
                />

                {errors.address_2 && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
            )}
          </div>
          <div
            className={classes.buttonasc}
            style={{ marginTop: "10px", textAlign: "left" }}
          >
            {/* {!defaultValue && ( */}
            <button
              type="submit"
              className={classes.Subbtn}
              onClick={HandleShow}
              style={{ width: "80px" }}
            >
              Submit
            </button>
          </div>
        </form>
      )}
      {intitialStep && (
        <AdditionalTen proId={proId} setInitialStep={setInitialStep} />
      )}
    </div>
  );
};

export default StepTen;
