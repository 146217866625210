import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "./Search.module.css";
import { useToast } from "../../../context/ToastProvder";
import ListWithSearch from "../../Common/ListWithSearch/ListWithSearch";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
const SearchComp = ({
  onSearch,
  filterD,
  setProduct,
  setMonth,
  setStatus,
  setStep,
}) => {
  const { register, handleSubmit, reset, watch, errors, control, setValue } =
    useForm();
  // const onSubmit = (data) => {
  //   console.log("searchForm submitted:", data);
  //   onSearch(data);
  // };<SearchComp
  //   onSearch={searchDataHandler}
  //   filterD={filterD}
  //   setStep
  //   setProduct
  //   setStatus
  //   setMonth
  // />
  console.log("filterD", filterD);
  useEffect(() => {
    if (filterD) {
      setValue("borrower_name", filterD.borrower_name);
      setValue("borrower_id", filterD.borrower_id);
      setValue("mobile_no", filterD.mobile_no);
      setValue("dob", filterD.dob);
      setValue("pan_no", filterD.pan_no);
      setValue("aadhar_no", filterD.aadhar_no);
      setValue("application_date", filterD.application_date);
      setValue("dealer_id", filterD.dealer_id);
      setValue("lsp_id", filterD.lsp_id);
      setValue("start_date", filterD.start_date);
      setValue("end_date", filterD.end_date);
    }
  }, [filterD, setValue]);
  const [{ data: lspList }, { data: lenderList }, { data: dealerList }] =
    useQueries({
      queries: [
        {
          queryKey: ["department"],
          queryFn: () => api.get("admin/lsp-screener").then((res) => res.data),
          initialData: [],
        },
        {
          queryKey: ["userType"],
          queryFn: () =>
            api.get("admin/lender-screener").then((res) => res.data),
          initialData: [],
        },
        {
          queryKey: ["dealers"],
          queryFn: () => api.get("dealer-screener").then((res) => res.data),
          initialData: [],
        },
      ],
    });

  console.log("user in adlsp", lspList);
  const { showToast } = useToast();
  const onSubmit = (data) => {
    const { start_date, end_date } = data;

    // Check if end date is selected without a start date
    if (end_date && !start_date) {
      showToast("Please Select Start Date First");
      return;
    }
    if (end_date && start_date && end_date < start_date) {
      showToast(
        "The end date field must be a date after or equal to start date."
      );
      return;
    }
    if (data.pan_no && data.pan_no.length !== 10) {
      showToast("Invalid PAN Number.");
      return;
    }
    if (data.aadhar_no && data.aadhar_no.length !== 12) {
      showToast("Invalid Aadhar Number.");
      return;
    }
    console.log("searchForm submitted:", data);
    onSearch(data);
  };

  const resetHandler = () => {
    reset();
    onSearch(null);
    setProduct(null);
    setMonth(null);
    setStatus(null);
    setStep(null);
  };
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.fourinputasc} style={{ gap: "20px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Start Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("start_date")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              End Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("end_date")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Borrower Name
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("borrower_name")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Borrower ID
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("borrower_id")}
            />
          </div>
        </div>
        <div
          className={styles.fourinputasc}
          style={{ gap: "20px", marginTop: "10px" }}
        >
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Mobile No
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("mobile_no")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Date of Birth
            </label>
            <input
              type="date"
              className={styles.inpttsc}
              {...register("dob")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              PAN No
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("pan_no")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Aadhar No
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("aadhar_no")}
            />
          </div>
        </div>
        <div className={styles.threeinputads} style={{ marginTop: "10px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Application Date
            </label>
            <input
              type="date"
              className={styles.inpttsc}
              {...register("application_date")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer
            </label>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <ListWithSearch
                  options={dealerList?.data || []}
                  getOptionLabel={(val) => val?.name || ""}
                  renderOption={(props, option) => (
                    <li {...props} key={option?.id}>{`${option?.name}`}</li>
                  )}
                  className={styles.listSrch}
                  onChange={(event, selectedValue) => {
                    onChange(selectedValue ? selectedValue?.id : null);
                  }}
                  value={
                    dealerList?.data?.find(
                      (employee) => employee?.id === value
                    ) || null
                  }
                />
              )}
              name={`dealer_id`}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              LSP
            </label>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <ListWithSearch
                  options={lspList?.data || []}
                  getOptionLabel={(val) => val.name || ""}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>{`${option.name}`}</li>
                  )}
                  className={styles.listSrch}
                  onChange={(event, selectedValue) => {
                    onChange(selectedValue ? selectedValue?.id : null);
                  }}
                  value={
                    lspList?.data?.find((employee) => employee.id === value) ||
                    null
                  }
                />
              )}
              name={`lsp_id`}
            />
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button
            style={{
              maxHeight: "30px",
              border: "none",
              color: "#fff",
              background: "#45cb85",
              borderRadius: "5px",
              position: "relative",
              top: "14px",
              height: "25px",
            }}
            type="submit"
          >
            Search
          </button>
          <button
            style={{
              maxHeight: "30px",
              border: "none",
              color: "#fff",
              background: "#f06548",
              borderRadius: "5px",
              position: "relative",
              top: "14px",
              height: "25px",
            }}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComp;
