import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";

const AddModal = ({ addHandle, defaultValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    let payload;
    if (defaultValue) {
      payload = {
        employeeId: defaultValue.empId,
        startDate: data.startDate,
        vpfAmount: data.vpfAmount,
        calculatedOn: "amount",
      };
      console.log("updatedData", payload);
     
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "750px" }}
      className={styles.modalcontainer}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {defaultValue
            ? "Edit Products/Models/Assets"
            : "Add Products/Models/Assets"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div className={styles.modalform}>
          <div className={styles.twoninputcont}>
            <div className={styles.inptconnt}>
              <label
                style={{
                  marginBottom: "0.5rem",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Dealer
              </label>
              <select
                {...register("startDate", { required: true })}
                className={styles.mdselct}
              >
                <option disabled>--Select Product Type--</option>
              </select>
              {errors.startDate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.inptconnt}>
              <label
                style={{
                  marginBottom: "0.5rem",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Product Type
              </label>
              <select
                {...register("startDate", { required: true })}
                className={styles.mdselct}
              >
                <option disabled>--Select Product Type--</option>
              </select>
              {errors.startDate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.twoninputcont}>
            <div className={styles.inptconnt}>
              <label
                style={{
                  marginBottom: "0.5rem",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Vehicle Type
              </label>
              <select
                {...register("startDate", { required: true })}
                className={styles.mdselct}
              >
                <option disabled>--Select Vehicle Type--</option>
              </select>
              {errors.startDate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.inptconnt}>
              <label
                style={{
                  marginBottom: "0.5rem",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Model Name
              </label>
              <input
                {...register("startDate", { required: true })}
                type="text"
                className={styles.mdlinput}
              />
              {errors.startDate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.twoninputcont}>
            <div className={styles.inptconnt}>
              <label
                style={{
                  marginBottom: "0.5rem",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Battery Type
              </label>
              <select
                {...register("startDate", { required: true })}
                className={styles.mdselct}
              >
                <option disabled>--Select Battery Type--</option>
              </select>
              {errors.startDate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.inptconnt}>
              <label
                style={{
                  marginBottom: "0.5rem",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Manufacturer Name
              </label>
              <select
                {...register("startDate", { required: true })}
                className={styles.mdselct}
              >
                <option disabled>--Select Manufacturer Name--</option>
              </select>

              {errors.startDate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.twoninputcont}>
            <div className={styles.inptconnt}>
              <label
                style={{
                  marginBottom: "0.5rem",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Ex-Showroom Price
              </label>
              <input
                {...register("startDate", { required: true })}
                type="number"
                className={styles.mdlinput}
              />
              {errors.startDate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.inptconnt}>
              <label
                style={{
                  marginBottom: "0.5rem",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Loan to Value Ratio
              </label>
              <input
                {...register("startDate", { required: true })}
                type="number"
                className={styles.mdlinput}
              />
              {errors.startDate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.twoninputcont}>
            <div className={styles.inptconnt}>
              <label
                style={{
                  marginBottom: "0.5rem",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Min Loan Amount
              </label>
              <input
                {...register("startDate", { required: true })}
                type="text"
                className={styles.mdlinput}
              />
              {errors.startDate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.inptconnt}>
              <label
                style={{
                  marginBottom: "0.5rem",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Max Loan Amount
              </label>
              <input
                {...register("startDate", { required: true })}
                type="text"
                className={styles.mdlinput}
              />
              {errors.startDate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.twoninputcont}>
            <div className={styles.inptconnt}>
              <label
                style={{
                  marginBottom: "0.5rem",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Min Loan Tenure
              </label>
              <input
                {...register("startDate", { required: true })}
                type="text"
                className={styles.mdlinput}
              />
              {errors.startDate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.inptconnt}>
              <label
                style={{
                  marginBottom: "0.5rem",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Max Loan Tenure
              </label>
              <input
                {...register("startDate", { required: true })}
                type="text"
                className={styles.mdlinput}
              />
              {errors.startDate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddModal;
