import React, { useState, useEffect } from "react";
import styles from "./MonthBtn.module.css";
const MonthBtn = ({ selectedMonth, setSelectedMonth }) => {
  const months = [
    { label: "Jan", value: "January" },
    { label: "Feb", value: "February" },
    { label: "Mar", value: "March" },
    { label: "Apr", value: "April" },
    { label: "May", value: "May" },
    { label: "Jun", value: "June" },
    { label: "Jul", value: "July" },
    { label: "Aug", value: "August" },
    { label: "Sep", value: "September" },
    { label: "Oct", value: "October" },
    { label: "Nov", value: "November" },
    { label: "Dec", value: "December" },
  ];

  useEffect(() => {
    const currentMonthIndex = new Date().getMonth(); // Get the current month (0-11)
    setSelectedMonth(months[currentMonthIndex].value);
  }, []);
  const handleMonthClick = (month) => {
    setSelectedMonth(month.value);
  };
  return (
    <div style={{ marginTop: "15px", display: "grid" }}>
      <div className={styles.monthBoxes}>
        {months.map((month) => (
          <button
            className={
              month.value === selectedMonth
                ? styles.selectMonthBtn
                : styles.monthntn
            }
            onClick={() => handleMonthClick(month)}
          >
            {month.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MonthBtn;
