import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";

const ErrModal = ({ addHandle, err }) => {
  console.log("err", err);
  return (
    <form
      style={{ width: "750px" }}
      className={styles.modalcontainer}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>Errors</h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div className={styles.modalform}>
          <div className={styles.errorList}>
            {err.map((error, index) => (
              <p
                style={{ color: "red", fontWeight: "bold", fontSize: "11px" }}
                key={index}
                className={styles.errorItem}
              >
                {error}
              </p>
            ))}
          </div>
        </div>
      </div>

      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button className={styles.closebtn} onClick={addHandle}>
              Close
            </button>
            {/* <button type="submit" className={styles.Subbtn}>
              Submit
            </button> */}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ErrModal;
