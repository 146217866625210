import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
// import SelectModal from "./SelectModal";
import classes from "./Language.module.css";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { LoanContext } from "../../../context/LoanContext";
import { useToast } from "../../../context/ToastProvder";
const Step2 = () => {
  const {
    activeStep,
    setActiveStep,
    userD,
    setUserD,
    borrId,
    setBorrId,
    handleNext,
    userId,
    setUserId,
  } = useContext(LoanContext);
  const [mdl, setMdl] = useState(null);
  const { showToast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    setValue,
  } = useForm();
  const product = JSON.parse(watch("product_id") || "{}");
  const dealer = JSON.parse(watch("dealer_id") || "{}");
  const category = watch("product_type");
  const [selectedModel, setSelectedModel] = useState(null);

  // Handle radio button change
  const handleModelSelect = (model) => {
    setSelectedModel(model);
    setMdl(model); // Set the selected model in the parent component
  };

  const [
    { data: langList },
    { data: genderList },
    { data: productList },
    { data: catergoryList },
    { data: dealerList },
    { data: modelList },
  ] = useQueries({
    queries: [
      {
        queryKey: ["lang"],
        queryFn: () => api.get("get-languages").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["genderl"],
        queryFn: () => api.get("get-genders").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["product"],
        queryFn: () => api.post("get-product-list").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["catego", product],
        queryFn: async () => {
          const payload = { product_id: product?.product_id };
          const res = await api.post("get-sub-product-list", payload);
          return res.data;
        },
        enabled: !!product,
        initialData: [],
      },
      {
        queryKey: ["dealers"],
        queryFn: () => api.get("dealer-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["modall", dealer, category, product],
        queryFn: async () => {
          const payload = {
            dealer_id: dealer.dealer_unq,
            product_type: product?.name,
            sub_product_type: category,
          };
          const res = await api.post("admin/get-models", payload);
          return res.data;
        },
        enabled: !!dealer && !!category && !!product,
        initialData: [],
      },
    ],
  });
  console.log("mdl", mdl);
  // const onSubmit = async (data) => {
  //   let payload;
  //   payload = {
  //     user_id: userD?.id,
  //     preferred_language: data.preferred_language,
  //     dealer_id: dealer.id,
  //     model: category,
  //     loan_amount: data.loan_amount,
  //     loan_term: data.loan_term,
  //     product_id: product.product_id,
  //     model_id: mdl?.model_unq,
  //   };

  //   console.log("updatedData", payload);
  //   updateVpnMutation.mutate(payload);
  // };
  // const updateVpnMutation = useCustomMutation({
  //   url: "admin/store-basic-details",
  //   method: "post",
  //   invalidateKey: "userlist",
  //   successCallback: () => {
  //     handleNext();
  //     // reset();
  //     // addHandle();
  //   },
  // });
  console.log("dealer", dealer);
  const onSubmit = async (data) => {
    try {
      const payload = {
        user_id: userD?.id || userId,
        preferred_language: data.preferred_language,
        dealer_id: dealer.dealer_unq,
        model: mdl?.name,
        loan_amount: data.loan_amount,
        loan_term: data.loan_term,
        product_id: product.product_id,
        model_id: mdl?.model_unq,
      };

      console.log("updatedData", payload);

      const response = await api.post("admin/store-basic-details", payload);

      if (response?.data?.status === "success") {
        const { status, message } = response.data;
        showToast(message, status);
        setBorrId(response.data.data.id);
        handleNext();
      } else {
        console.error("Error in response:", response);
      }
    } catch (error) {
      const { message, errors } = error.response.data;
      console.log("message", message);
      showToast(message, "error");
      // You can add additional error handling or show an error toast/message here
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.tablco}>
      <div className={classes.totalcheckconnn}>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Preferred Language<span className={classes.requ}>*</span>
          </label>

          <select
            {...register("preferred_language", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option value="">--Select Language--</option>
            {langList?.data?.map((row) => (
              <option key={row?.id} value={row?.id}>
                {row?.language}
              </option>
            ))}
          </select>
          {errors.preferred_language && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Product Type<span className={classes.requ}>*</span>
          </label>

          <select
            {...register("product_id", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option value="">--Select Product Type--</option>

            {productList?.data?.map((row) => (
              <option key={row?.product_id} value={JSON.stringify(row)}>
                {row?.name}
              </option>
            ))}
          </select>
          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Product Category<span className={classes.requ}>*</span>
          </label>

          <select
            {...register("product_type", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option value="">--Select Product Category--</option>

            {catergoryList?.data?.map((row) => (
              <option key={row} value={row}>
                {row}
              </option>
            ))}
          </select>
          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Dealer<span className={classes.requ}>*</span>
          </label>

          <select
            {...register("dealer_id", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option value="">--Select Dealer--</option>

            {dealerList?.data?.map((row) => (
              <option key={row.id} value={JSON.stringify(row)}>
                {row.name}
              </option>
            ))}
          </select>
          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      ></div>
      <hr style={{ border: "0.5px solid #ddd", marginTop: "10px" }} />

      <div>
        {modelList?.data?.result && (
          <p
            style={{
              textAlign: "left",
              padding: "10px 0",
              fontSize: "12px",
              fontWeight: "bold",
              color: "#212529",
            }}
          >
            SELECT MODEL
          </p>
        )}
        <div className={classes.totalcheckfhfnn}>
          {/* start */}
          {modelList?.data?.result?.map((row, i) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
              className={classes.shdwtpbx}
            >
              <div
                style={{
                  display: "flex",

                  gap: "5px",
                }}
              >
                <input
                  type="radio"
                  name="model" // Name should be the same for radio buttons in a group
                  checked={selectedModel === row} // Check if this model is selected
                  onChange={() => handleModelSelect(row)} // Update state on change
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    textAlign: "left",
                    color: "#212529",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  <p>{row?.name}</p>
                  <p
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {row.battery_type}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",

                  textAlign: "right",
                  color: "#212529",
                }}
              >
                <p
                  style={{
                    fontSize: "11px",
                  }}
                >
                  Price
                </p>
                <p
                  style={{
                    fontSize: "13px",
                    color: "#25a546",
                    fontWeight: "bold",
                  }}
                >
                  {row?.ex_showroom_price}
                </p>
              </div>
            </div>
          ))}
        </div>
        {mdl && (
          <div
            className={classes.totalcheckconnn}
            style={{ margin: "15px 0", paddingTop: "10px" }}
          >
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Loan Amount
                <span className={classes.requ}>
                  *{" "}
                  <span style={{ fontSize: "10px" }}>
                    Maximum Loan Amount : ₹{mdl?.max_loan_amount}
                  </span>
                </span>
              </label>
              <input
                {...register("loan_amount", {
                  required: true,
                  min: mdl?.min_loan_amount, // Set minimum value from mdl
                  max: mdl?.max_loan_amount, // Set maximum value from mdl
                })}
                type="number"
                step="0.01"
                className={classes.inputstst}
              />

              {errors.loan_amount && (
                <span className={classes.error}>
                  This Field is Missing or out of range.
                </span>
              )}
            </div>
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Loan Term in Month(s)
                <span className={classes.requ}>
                  *
                  <span style={{ fontSize: "10px" }}>
                    Maximum Loan Term : {mdl?.max_loan_tenure} M
                  </span>
                </span>
              </label>
              {/* <input
                  // placeholder="Enter Name"
                  {...register("name", { required: true })}
                  type="text"
                  className={classes.inputstst}
                /> */}
              <select
                {...register("loan_term", { required: true })}
                className={classes.inputstst}
                style={{ height: "27.5px" }}
              >
                <option value="">--Select Loan Term--</option>
                {Array.from(
                  { length: mdl?.max_loan_tenure - mdl?.min_loan_tenure + 1 },
                  (_, i) => (
                    <option key={i} value={mdl?.min_loan_tenure + i}>
                      {mdl?.min_loan_tenure + i}
                    </option>
                  )
                )}
              </select>
              {errors.loan_term && (
                <span className={classes.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
        )}
        <div
          className={classes.buttonasc}
          style={{ marginTop: "10px", textAlign: "left" }}
        >
          {mdl && (
            <button
              type="submit"
              className={classes.Subbtn}
              style={{ width: "80px" }}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default Step2;
