import React, { useContext, useEffect, useState } from "react";
import classes from "./Language.module.css";
import { useNavigate } from "react-router-dom";
import AdditionalSeven from "./AdditionalSeven";
import Thankyou from "../../../icons/Thnaks.png";
import BorroDoc from "./BorroDoc";
import { LoanContext } from "../../../context/LoanContext";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import { useToast } from "../../../context/ToastProvder";
const SevenEight = ({ setActiveStep }) => {
  const [intitialStep, setInitialStep] = useState(false);
  const { borrId, dealerId } = useContext(LoanContext);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [{ data: relationList }] = useQueries({
    queries: [
      {
        queryKey: ["rela", borrId, dealerId],
        queryFn: async () => {
          const payload = { borrower_id: borrId, lender_id: dealerId };
          const res = await api.post("admin/get-loan-terms", payload);
          return res.data;
        },
        enabled: !!borrId && !!dealerId,
        initialData: [],
      },
    ],
  });
  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          console.log("Loan Status:", response.data);

          // const { product_id } = response?.data;
          const { step } = response?.data?.step_details;

          if (step === 10) {
            setInitialStep(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  const handleConcent = async (consent) => {
    try {
      const payload = {
        borrower_id: borrId,
        lender_consent: consent,
      };

      const response = await api.post("admin/lender-consent", payload);
      const { status, message } = response.data;
      if (response.status === 200 || response.data.status === "success") {
        console.log("API call successful:", response.data);
        setInitialStep(true);
        showToast(message, status);
        if (consent === "NO") {
          navigate("/dashboard");
        }
      }
    } catch (error) {
      const { message, data: errorDetails } = error.response.data;
      showToast(message, "error");
      console.error(
        "Error while submitting data:",
        error.response?.data || error.message
      );
    }
  };
  return (
    <div
      className={classes.tablco}
      // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    >
      {!intitialStep && (
        <div
          style={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            padding: "15px",
            width: "60%",
            margin: "0 auto",
            textAlign: "left",
            borderRadius: "7px",
          }}
        >
          {/* <img src={Thankyou} style={{ width: "300px" }} /> */}
          <p className={classes.thnktt}>You Have Selected A Lender</p>
          <p className={classes.btmthnktt}>{relationList?.data?.description}</p>
          <div>
            <p className={classes.bnkNamet}>
              Lender's Name: {relationList?.data?.lender}
            </p>
            <p className={classes.bnkNamett}>Process By Finayo</p>
            <ul className={classes.bnkNamett}>
              {relationList?.data?.process_by_finayo?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div style={{ marginTop: "15px" }}>
            <button
              type="button"
              onClick={() => handleConcent("NO")}
              className={classes.Subbtn}
              style={{
                width: "80px",
                background: "#c6131b",
                marginRight: "15px",
              }}
            >
              Reject
            </button>
            <button
              type="button"
              className={classes.Subbtn}
              onClick={() => handleConcent("YES")}
              style={{ width: "80px", background: "#45cb85" }}
            >
              Accept
            </button>
          </div>
        </div>
      )}
      {intitialStep && <BorroDoc />}
    </div>
  );
};

export default SevenEight;
