import React, { useState } from "react";
import styles from "./Modal.module.css";
import { style } from "@mui/system";

const Modal = ({ addHandle }) => {
  return (
    <div
      className={styles.modalcontainer}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>Change your password</h3>
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <form className={styles.modalform}>
          <lebel style={{ marginBottom: "0.5rem", color: "#494646" }}>
            Enter Mobile Number
          </lebel>
          <input
            type="number"
            className={styles.mdlinput}
            placeholder="Ex.9867543456"
          />
        </form>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              style={{
                background: "#6c757d",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                padding: "6px",
              }}
              className={style.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button
              style={{
                background: "linear-gradient(310deg, #1857a5, #28a745)",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                padding: "6px",
              }}
              className={style.Subbtn}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
