import React, { useContext, useEffect, useState } from "react";
import classes from "./Language.module.css";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import api from "../../Common/api";
import { useQuery } from "@tanstack/react-query";
import { LoanContext } from "../../../context/LoanContext";
import { RiCheckboxCircleFill } from "react-icons/ri";

const AdditionalElev = () => {
  const { borrId, handleNext, setActiveStep } = useContext(LoanContext);
  const { data: relationList, refetch } = useQuery(
    ["rela", borrId],
    async () => {
      const payload = { borrower_id: borrId };
      const res = await api.post(
        "admin/get-field-verification-status",
        payload
      );
      return res.data;
    },
    {
      enabled: !!borrId,
      initialData: [],
    }
  );
  useEffect(() => {
    if (relationList) {
      if (relationList?.data?.is_verified === true) {
        setActiveStep(11);
        console.log("one true");
      } else {
        console.log("References not verified.");
      }

      //  setCheckStatus(false); // Reset after checking
    }
  }, [relationList]);
  return (
    <div>
      <div className={classes.totalcheckconnn}>
        {/* start */}
        <div className={classes.applylonffer}>
          <p
            style={{ padding: "6px", fontSize: "13px" }}
            className={classes.applyluptxt}
          >
            FIELD INSPECTION REQUEST STATUS
          </p>
          <div className={classes.divfrtheredr}>
            <div className={classes.notAppre}>
              {relationList?.data?.is_verified ? (
                <RiCheckboxCircleFill
                  size="25"
                  style={{ background: "green", borderRadius: "100%" }}
                />
              ) : (
                <RxCrossCircled size="25" />
              )}
            </div>
            <div className={classes.applylonfthn}>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Name</p>
                <p className={classes.txtfooflbtoneffs}>
                  {" "}
                  {relationList?.data?.name}
                </p>
              </div>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Mobile</p>
                <p className={classes.txtfooflbtoneffs}>
                  {" "}
                  {relationList?.data?.mobile}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* emd */}
      </div>
      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      >
        {/* {!defaultValue && ( */}
        <button
          type="button"
          onClick={() => refetch()}
          className={classes.Subbtn}
          style={{
            width: "120px",
            background: "rgb(69, 203, 133)",
            marginRight: "12px",
          }}
        >
          Check Status
        </button>
        {/* <button
          type="button"
          onClick={() => refetch()}
          className={classes.Subbtn}
          style={{ width: "120px", background: "rgb(198, 19, 27)" }}
        >
          Skip
        </button> */}
      </div>
    </div>
  );
};

export default AdditionalElev;
