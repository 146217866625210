import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import sampleimg from "./../../../icons/sampleimg.png";

const ViewImg = ({ zoomImgUrl, addHandle }) => {
  const fullImgUrl = `https://insta.finayo.tech/${zoomImgUrl}`;
  // console.log("fullImgUrl", fullImgUrl);
  return (
    <form
      style={{ minWidth: "400px" }}
      className={styles.modalcontainerr}
      onClick={(event) => event.stopPropagation()}
    >
      <div style={{ textAlign: "right" }}>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            right: "5px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red", display: "flex", gap: "10px" }}
      >
        <img src={fullImgUrl} style={{ width: "600px", maxHeight: "500px" }} />
      </div>

      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            {/* <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button> */}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ViewImg;
