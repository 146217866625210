import React from "react";

const OtpInput = ({ register, name, length = 6 }) => {
  const handleInput = (e, index) => {
    const value = e.target.value;
    const nextSibling = e.target.nextElementSibling;

    // Automatically focus next input when a digit is entered
    if (value && nextSibling) {
      nextSibling.focus();
    }
  };

  return (
    <div style={{ display: "flex", gap: "5px" }}>
      {[...Array(length)].map((_, index) => (
        <input
          key={index}
          {...register(`${name}[${index}]`, { required: true })}
          type="text"
          maxLength="1"
          style={{ width: "40px", textAlign: "center" }}
          onInput={(e) => handleInput(e, index)}
        />
      ))}
    </div>
  );
};

export default OtpInput;
