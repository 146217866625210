import React from "react";
import styles from "./Breadcum.module.css";
import { GrFormNext } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
const Breadcum = ({ pageName }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.brdcon}>
      <p className={styles.pagename}>{pageName}</p>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <p className={styles.dashb} onClick={() => navigate("/dashboard")}>
          Dashboard
        </p>
        <p style={{ color: "#878a99", fontSize: "13px" }}>
          <span>
            <GrFormNext
              className={styles.nxtico}
              style={{ paddingRight: "10px" }}
            />
          </span>
          {pageName}
        </p>
      </div>
    </div>
  );
};

export default Breadcum;
