import React, { useState } from "react";
import styles from "./Table.module.css";

const Table = ({ rowData, page, numberOfRecords, perm }) => {
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      <div className={styles.tabwrap}>
        {" "}
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                background: "linear-gradient(to bottom, #12468a, #235392)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th>#</th>
                <th>Borrower ID</th>
                <th>User Type</th>
                <th>Application Type</th>
                <th>Action</th>
                <th>Details</th>
                <th>Created By</th>
                <th>Created At</th>
                <th>IP Address</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td>{row.borrower_id}</td>
                  <td>{row.user_type}</td>
                  <td>{row.application_type}</td>
                  <td>{row.action}</td>
                  <td>{row.details}</td>
                  <td>{row.created_by}</td>
                  <td>{row.created_at}</td>
                  <td>{row.ip_address}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
      </div>
    </div>
  );
};

export default Table;
