import React, { useContext, useEffect, useState } from "react";
import { Stepper, Step, StepLabel, Button, Box } from "@mui/material";
import SelectType from "./Step1";
import Breadcum from "../../Common/Breadcum";
import classes from "./Language.module.css";
import Step2 from "./Step2";
import ThirdStep from "./ThirdStep";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import SevenStep from "./SevenStep";
import SevenEight from "./SevenEight";
import StepNine from "./StepNine";
import StepTen from "./StepTen";
import StepElev from "./StepElev";
import StepTwel from "./StepTwel";
import ThirteenStep from "./ThirteenStep";
import StepFoutte from "./StepFoutte";
import Step1 from "./Step1";
import StepFifthee from "./StepFifthee";
import api from "../../Common/api";
import { LoanContext } from "../../../context/LoanContext";
import { useLocation, useNavigate } from "react-router-dom";
import EsingGen from "./EsingGen";
import { useToast } from "../../../context/ToastProvder";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "../../../context/AuthContext";
const steps = [
  "Step 1: ",
  "Step 2:",
  "Step 3: ",
  "Step 4:",
  "Step 5:",
  "Step 6:",
  "Step 7:",
  "Step 8:",
  "Step 9:",
  "Step 10:",
  "Step 11:",
  "Step 12:",
  "Step 13:",
  "Step 14:",
  "Step 15:",
];

const NewApplyLoan = () => {
  const {
    activeStep,
    setActiveStep,
    userD,
    setUserD,
    borrId,
    setBorrId,
    dataFromAdhar,
    setDataFromAdhar,
    proId,
    setProId,
    userId,
    setUserId,
  } = useContext(LoanContext);
  const { showToast } = useToast();
  const location = useLocation();
  const [perm, setPerm] = useState([]);
  const { activeSubmenuId } = useContext(AuthContext);
  useEffect(() => {
    setActiveStep(0);
    setBorrId(null);
    setUserD(null);
  }, []);
  const fetchPermissions = async () => {
    const response = await api.get(
      `admin/get-sub-menu-permissions/${activeSubmenuId}`
    );
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions", activeSubmenuId],
    queryFn: fetchPermissions,
    enabled: !!activeSubmenuId,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  const { data: permissionsData } = PermissionsResponse;
  //   const navigate = useNavigate();
  //   const { row } = location.state || "";
  //   console.log("proId", proId);
  //   console.log("row", row);
  //   useEffect(() => {
  //     if (row) {
  //       setActiveStep(0);
  //     }
  //     if (row && row.borrower_id) {
  //       setBorrId(row.borrower_id);
  //       setUserId(row.user_id);
  //       api
  //         .post("admin/get-loan-status", {
  //           borrower_id: row.borrower_id,
  //         })
  //         .then((response) => {
  //           console.log("Loan Status:", response.data);

  //           // const { product_id } = response?.data;
  //           const { step } = response?.data?.step_details;

  //           if (step === 1) {
  //             setActiveStep(2);
  //           } else if (step === 2) {
  //             setActiveStep(3);
  //           } else if (step === 3) {
  //             setActiveStep(3);
  //           } else if (step === 4) {
  //             setActiveStep(4);
  //           } else if (step === 5) {
  //             setActiveStep(4);
  //           } else if (step === 6) {
  //             setActiveStep(5);
  //           } else if (step === 7) {
  //             setActiveStep(5);
  //           } else if (step === 8) {
  //             setActiveStep(6);
  //           } else if (step === 9) {
  //             setActiveStep(6);
  //           } else if (step === 10) {
  //             setActiveStep(7);
  //           } else if (step === 11) {
  //             setActiveStep(8);
  //           } else if (step === 12) {
  //             setActiveStep(8);
  //           } else if (step === 13) {
  //             setActiveStep(9);
  //           } else if (step === 14) {
  //             setActiveStep(9);
  //           } else if (step === 15) {
  //             setActiveStep(10);
  //           } else if (step === 16) {
  //             setActiveStep(10);
  //           } else if (step === 17) {
  //             setActiveStep(11);
  //           } else if (step === 18) {
  //             setActiveStep(11);
  //           } else if (step === 19) {
  //             setActiveStep(12);
  //           } else if (step === 20) {
  //             setActiveStep(12);
  //           } else if (step === 21) {
  //             setActiveStep(12);
  //           } else if (step === 22) {
  //             setActiveStep(13);
  //           } else if (step === 23) {
  //             setActiveStep(13);
  //           } else if (step === 24) {
  //             setActiveStep(13);
  //           } else if (step === 25) {
  //             setActiveStep(13);
  //           } else if (step === 26) {
  //             setActiveStep(14);
  //           } else if (step === 27) {
  //             setActiveStep(14);
  //           } else if (step === 28) {
  //             showToast("Your Application is Submitted Successfully", "success");
  //             navigate("/dashboard");
  //           } else {
  //             setActiveStep(0);
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching loan status:", error);
  //         });
  //     } else if (row && row.user_id) {
  //       setUserId(row.user_id);
  //       setActiveStep(1);
  //     }
  //   }, [row]);
  const handleStepClick = (step) => {
    setActiveStep(step);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  console.log("userD", userD);
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  console.log("borrId", borrId);
  console.log("activeStep", activeStep);
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Step1
            setActiveStep={setActiveStep}
            handleNext={handleNext}
            setUserD={setUserD}
          />
        );
      case 1:
        return (
          <Step2
            userD={userD}
            handleNext={handleNext}
            setUserD={setUserD}
            setBorrId={setBorrId}
          />
        );

      case 2:
        return (
          <ThirdStep
            borrId={borrId}
            userD={userD}
            handleNext={handleNext}
            setDataFromAdhar={setDataFromAdhar}
            dataFromAdhar={dataFromAdhar}
          />
        );
      case 3:
        return <StepFour />;
      case 4:
        return <StepFive />;
      case 5:
        return <StepSix />;
      case 6:
        return <SevenStep />;
      case 7:
        return <SevenEight />;
      case 8:
        return <StepNine />;
      case 9:
        return <StepTen />;
      case 10:
        return <StepElev />;
      case 11:
        return <StepTwel />;
      case 12:
        return <ThirteenStep />;
      case 13:
        return <EsingGen />;
      case 14:
        return <StepFoutte />;
      default:
        return "Unknown Step";
    }
  };

  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <Breadcum pageName="Apply Loan" />
      <div className={classes.mainBodyContainer}>
        <div
          className={classes["main-body"]}
          style={{ padding: "15px", background: "#fff" }}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index} onClick={() => handleStepClick(index)}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box mt={2}>{getStepContent(activeStep)}</Box>

          {/* <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="contained"
            >
              Back
            </Button>
            <Button
              disabled={activeStep === steps.length - 1}
              onClick={handleNext}
              variant="contained"
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box> */}
        </div>
      </div>
    </div>
  );
};

export default NewApplyLoan;
