import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import classes from "./Language.module.css";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { LoanContext } from "../../../context/LoanContext";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import { useToast } from "../../../context/ToastProvder";
const BorrowerOccupation = () => {
  const { userD, handleNext, borrId, dataFromAdhar, setDataFromAdhar } =
    useContext(LoanContext);
  const { showToast } = useToast();
  const [proId, setProId] = useState(null);
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [{ data: occType }, { data: vehType }] = useQueries({
    queries: [
      {
        queryKey: ["occType"],
        queryFn: () => api.get("get-occupations").then((res) => res.data),
        initialData: [],
      },

      {
        queryKey: ["vehType"],
        queryFn: () => api.get("get-vehicle-types").then((res) => res.data),
        initialData: [],
      },
    ],
  });

  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          console.log("Loan Status:", response.data);

          const { product_id } = response?.data;
          const { step } = response?.data?.step_details;
          setProId(product_id);
          //  if (step === 3) {
          //    setFamilyStep(true);
          //  }
        })
        .catch((error) => {
          console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  const monthlyIncome = watch(
    proId === "4" ? "monthly_income" : "expected_monthly_income"
  );
  // const monthlyIncome = watch("expected_monthly_income");
  console.log("monthlyIncome", monthlyIncome);
  useEffect(() => {
    if (monthlyIncome) {
      setValue("total_yearly_income", monthlyIncome * 12);
    } else {
      setValue("total_yearly_income", null);
    }
  }, [monthlyIncome, setValue]);
  const onSubmit = async (data) => {
    let payload;
    payload = {
      borrower_id: borrId,
      occupation_type: data.occupation_type,
      current_occupation: data.current_occupation,
      driving_experience: data.driving_experience,
      vehicle_type: data.vehicle_type,
      monthly_income: data.monthly_income,
      expected_monthly_income: data.expected_monthly_income,
      other_income: data.other_income,
      extra_source_of_income: data.extra_source_of_income,
      total_yearly_income: data.total_yearly_income,
    };

    console.log("Payload to be submitted:", payload);

    try {
      const response = await api.post(
        "admin/store-borrower-occupation-details",
        payload
      );
      const { status, message } = response.data;
      console.log("response.data", response.data);
      if (response.status === 200 || response.data.status === "success") {
        console.log("API call successful:", response.data);

        showToast(message, status);
        handleNext();
      }
    } catch (error) {
      const { message, data: errorDetails } = error.response.data;
      showToast(message, "error");
      console.error(
        "Error while submitting data:",
        error.response?.data || error.message
      );
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {" "}
      <hr style={{ border: "0.5px solid #ddd", margin: "20px 0" }} />
      <p className={classes.applheader}>BORROWER OCCUPATIONS :</p>
      <div className={classes.totalcheckconnn}>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Occupation Type<span className={classes.requ}>*</span>
          </label>

          <select
            {...register("occupation_type", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option value="">--Select Occupation Type--</option>
            {occType?.data?.map((row) => (
              <option value={row.id}>{row.occupation}</option>
            ))}
          </select>
          {errors.occupation_type && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Current Occupation<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Current Occupation"
            {...register("current_occupation", {
              required: true,
              validate: (value) =>
                /^[A-Za-z\s]+$/.test(value) ||
                "Only letters and spaces are allowed.",
            })}
            // {...register("current_occupation", { required: true })}
            type="text"
            className={classes.inputstst}
          />
          {errors.current_occupation && (
            <span className={classes.error}>
              {errors.current_occupation.message || "This Field is Missing."}
            </span>
          )}
        </div>
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Driving Exprience<span className={classes.requ}>*</span>
            </label>
            <input
              placeholder="Enter Driving Exprience"
              {...register("driving_experience", {
                required: "This Field is Missing.",
                min: {
                  value: 1,
                },
              })}
              type="number"
              className={classes.inputstst}
            />
            {errors.driving_experience && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Vehicle Type<span className={classes.requ}>*</span>
            </label>

            <select
              {...register("vehicle_type", { required: true })}
              className={classes.inputstst}
              style={{ height: "29px" }}
            >
              <option value="">--Select Vehicle Type--</option>
              {vehType?.data?.map((row) => (
                <option value={row}>{row}</option>
              ))}
            </select>
            {errors.vehicle_type && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Monthly Income<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Monthly Income"
            // {...register("monthly_income", { required: true })}
            {...register("monthly_income", {
              required: "This Field is Missing",
              min: {
                value: 15000,
                message: "Income must be greater than/equal to 15,000",
              },
            })}
            type="text"
            className={classes.inputstst}
          />

          {errors.monthly_income && (
            <span className={classes.error}>
              {errors.monthly_income.message}
            </span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Expected Monthly Income<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Expected Monthly Income"
            {...register("expected_monthly_income", {
              required: "This Field is Missing",
              min: {
                value: 15000,
                message: "Income must be greater than/equal to 15,000",
              },
            })}
            // {...register("expected_monthly_income", { required: true })}
            type="number"
            className={classes.inputstst}
          />
          {errors.expected_monthly_income && (
            <span className={classes.error}>
              {errors.expected_monthly_income.message}
            </span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Other Income
          </label>
          <input
            {...register("other_income", {
              min: {
                value: 1,
                message: "Income cannot be a Negative value",
              },
            })}
            type="number"
            className={classes.inputstst}
          />
          {errors.other_income && (
            <span className={classes.error}>{errors.other_income.message}</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Source
          </label>
          {/* <input
            {...register("extra_source_of_income", {
              validate: (value) =>
                /^[A-Za-z\s]+$/.test(value) ||
                "Only letters and spaces are allowed.",
            })}
            type="text"
            className={classes.inputstst}
          /> */}
          <input
            {...register("extra_source_of_income", {
              validate: (value) =>
                !value ||
                /^[A-Za-z\s]+$/.test(value) ||
                "Only letters and spaces are allowed.",
            })}
            type="text"
            className={classes.inputstst}
          />
          {errors.extra_source_of_income && (
            <span className={classes.error}>
              {errors.extra_source_of_income.message}
            </span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Total Yearly Income<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Total Yearly Income"
            {...register("total_yearly_income", { required: true })}
            type="number"
            disabled
            className={classes.inputstst}
          />

          {errors.total_yearly_income && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      >
        {/* {!defaultValue && ( */}
        <button type="submit" className={classes.Subbtn}>
          Submit
        </button>
        {/* )} */}
      </div>
    </form>
  );
};

export default BorrowerOccupation;
