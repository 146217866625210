import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import React, { createContext, useContext, useState } from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ToastContext = createContext();

export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
  const [toastState, setToastState] = useState({
    message: "",
    severity: "",
    open: false,
  });

  const showToast = (message, severity = "error") => {
    if (severity !== "error" && severity !== "success") {
      severity = "error";
    }
    setToastState({ message, severity, open: true });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastState((prevState) => ({ ...prevState, open: false }));
  };

  const ToastComponent = ({
    anchorOrigin,
    autoHideDuration,
    ...otherProps
  }) => (
    <Snackbar
      open={toastState.open}
      autoHideDuration={autoHideDuration || 3600}
      onClose={handleClose}
      disableWindowBlurListener={true}
      anchorOrigin={anchorOrigin || { vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={toastState.severity}
        {...otherProps}
        sx={
          {
            // textTransform: "capitalize",
          }
        }
      >
        {toastState.message}
      </Alert>
    </Snackbar>
  );

  return (
    <ToastContext.Provider value={{ showToast, handleClose }}>
      {children}
      <ToastComponent />
    </ToastContext.Provider>
  );
};
