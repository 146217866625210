import React, { useContext, useEffect, useState } from "react";
import classes from "./Language.module.css";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import api from "../../Common/api";
import { useQuery } from "@tanstack/react-query";
import { LoanContext } from "../../../context/LoanContext";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { AuthContext } from "../../../context/AuthContext";

const AdditionalTwel = ({ VName, VMob }) => {
  const { borrId, handleNext, setActiveStep, userId, userD } =
    useContext(LoanContext);
  const { ver } = useContext(AuthContext);
  const { data: relationList, refetch } = useQuery(
    ["rela", borrId],
    async () => {
      const payload = { borrower_id: borrId, user_id: userD?.id || userId };
      const res = await api.post("admin/check-vkyc-status", payload);
      return res.data;
    },
    {
      enabled: !!borrId,
      initialData: [],
    }
  );
  const { data: otherDet } = useQuery(
    ["rela", borrId, relationList],
    async () => {
      const payload = {
        borrower_id: borrId,
        user_id: userId,
        application_number: relationList?.data?.application_number,
      };
      const res = await api.post(`${ver}admin-get-vkyc-call-details`, payload);
      // const res = await api.post(`${ver}get-vkyc-call-details`, payload);
      return res.data;
    },
    {
      enabled: !!borrId && !!relationList?.data?.application_number,
      initialData: [],
    }
  );
  console.log("relationList", relationList);
  console.log("otherDet", otherDet);
  useEffect(() => {
    if (relationList) {
      if (relationList?.data?.status === "APPROVED") {
        setActiveStep(12);
        console.log("one true");
      } else {
        console.log("References not verified.");
      }
    }
    if (otherDet) {
      if (otherDet?.data?.vkycStatus === "APPROVED") {
        setActiveStep(12);
        console.log("one true");
      } else {
        console.log("References not verified.");
      }
    }
  }, [relationList, otherDet]);
  return (
    <div>
      {/* <p className={classes.applheader}>FIELD INSPECTION REQUEST STATUS</p> */}
      <div className={classes.totalcheckconnn}>
        {/* start */}
        <div className={classes.applylonffer}>
          <p
            style={{ padding: "6px", fontSize: "13px" }}
            className={classes.applyluptxt}
          >
            VIDEO KYC STATUS
          </p>
          <div className={classes.divfrtheredr}>
            <div className={classes.notAppre}>
              {relationList?.data?.is_verified ? (
                <RiCheckboxCircleFill
                  size="25"
                  style={{ background: "green", borderRadius: "100%" }}
                />
              ) : (
                <RxCrossCircled size="25" />
              )}
            </div>
            <div className={classes.applylonfthn}>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Name</p>
                <p className={classes.txtfooflbtoneffs}>
                  {relationList?.data?.name || VName}
                </p>
              </div>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Mobile</p>
                <p className={classes.txtfooflbtoneffs}>
                  {" "}
                  {relationList?.data?.mobile || VMob}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* emd */}
      </div>
      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      >
        {/* {!defaultValue && ( */}
        <button
          type="button"
          className={classes.Subbtn}
          onClick={() => refetch()}
          style={{
            width: "120px",
            background: "rgb(69, 203, 133)",
            marginRight: "12px",
          }}
        >
          Check Status
        </button>
        {/* <button
          type="button"
          className={classes.Subbtn}
          style={{ width: "120px", background: "rgb(198, 19, 27)" }}
        >
          Skip
        </button> */}
      </div>
    </div>
  );
};

export default AdditionalTwel;
