import React, { useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import Table from "./Table";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import Loader from "../../Common/Loader";
import SearchComp from "./SearchComp";
import { useLocation } from "react-router-dom";

const ViewLspDet = () => {
  const [perm, setPerm] = useState([]);
  const [page, setPage] = useState(1);
  const [add, setAdd] = useState(false);
  const [numberOfRecords, setNumberOfRecords] = useState(10);
  const [totalPage, setTotalPage] = useState(2);
  const [searchData, setSearchData] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const ShowSearchHandler = (event, value) => {
    setShowSearch((val) => !val);
  };
  const location = useLocation();
  const { val } = location.state || "";
  console.log("val", val);
  const searchDataHandler = (data) => {
    setSearchData(data);
    setPage(1);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  const addHandle = () => {
    setAdd((val) => !val);
  };

  const fetchData = async ({ page, numberOfRecords, searchData }) => {
    const response = await api.get(
      `admin/get-dealers-by-lsp-executive/${val.id}`
    );
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: ["langList", page, numberOfRecords, searchData],
    queryFn: () =>
      fetchData({
        page,
        numberOfRecords,
        searchData,
      }),

    onSuccess: (data) => {
      setTotalPage(data.data?.last_page);
    },
  });
  const handleRecordsChange = (event) => {
    setNumberOfRecords(event.target.value);
  };
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  console.log("dashboardList", dashboardList);
  const fetchPermissions = async () => {
    const response = await api.get("admin/get-sub-menu-permissions/49");
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions"],
    queryFn: fetchPermissions,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });

  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <Breadcum pageName="LSP Dealer Grouping" />
        <div className={classes["main-body"]} style={{ padding: "15px" }}>
          <div className={classes.tablco}>
            {showSearch && <SearchComp onSearch={searchDataHandler} />}
            <div className={classes.inputsearchcon}>
              <div style={{ display: "flex", gap: "10px" }}>
                {perm?.includes("create") && (
                  <button
                    type="button"
                    className={classes.addbtn}
                    onClick={addHandle}
                    style={{ width: "150px" }}
                  >
                    Group LSP Dealer
                  </button>
                )}
              </div>
              <div></div>
            </div>
            <div className={classes.tableContai}>
              {isLoading || isFetching ? (
                <Loader />
              ) : (
                <Table rowData={dashboardList?.data || []} perm={perm} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <AddModal addHandle={addHandle} val={val} />
      </Backdrop>
    </div>
  );
};

export default ViewLspDet;
