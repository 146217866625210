import React, { useContext, useEffect } from "react";
import styles from "./BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { Document, Page } from "react-pdf";
import { useState } from "react";
import { Dialog } from "@mui/material";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop } from "@mui/material";
// import ViewImg from "./ViewImg";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
const ViewDet = ({ defaultValue }) => {
  const [add, setAdd] = useState(false);
  const { token, baseURL, baseDoc } = useContext(AuthContext);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [existingData, setExistingData] = useState(null);
  const [err, setErr] = useState({});
  const [modalFocused, setModalFocused] = useState(true);

  const handleFocus = () => setModalFocused(true);
  const handleBlur = () => setModalFocused(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  const [open, setOpen] = React.useState(false);
  const disableShortcuts = (e) => {
    const blockedKeys = ["PrintScreen", "PrtSc", "Alt", "Ctrl", "Shift"];
    if (blockedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("contextmenu", disableRightClick);
    document.addEventListener("keydown", disableShortcuts);

    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("keydown", disableShortcuts);
    };
  }, []);
  useEffect(() => {
    const handleKeyUp = (e) => {
      if (e.key === "PrintScreen") {
        navigator.clipboard.writeText("");
        alert("Screenshots disabled!");
      }
    };

    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === "p") {
        alert("This section is not allowed to print or export to PDF");
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    };

    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // const handleViewPdf = (pdfUrl) => {
  //   if (pdfUrl) {
  //     window.open(pdfUrl, "_blank");
  //   }
  // };
  const [pdfUrl, setPdfUrl] = React.useState("");
  const disableRightClick = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    document.addEventListener("contextmenu", disableRightClick);
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);
  const handleViewPdf = (url) => {
    const fullUrl = `${baseDoc}${url}`;
    setPdfUrl(fullUrl);
    setOpen(true);
  };
  //  const handleViewPdf = (url) => {
  //    const fullUrl = `${baseDoc}${url}`;
  //    setPdfUrl(fullUrl);
  //    navigate(`/pdf-view`, { state: { pdfUrl: fullUrl } }); // Use navigate to redirect to PdfViewer with state
  //  };
  // const handleViewPdf = (pdfUrl) => {
  //   if (pdfUrl) {
  //     const fullUrl = `${baseDoc}${pdfUrl}`;
  //     window.open(fullUrl, "_blank");
  //   }
  // };
  const fetchData = async () => {
    const response = await api.get(`edit-dealer/${defaultValue.id}`);
    return response.data;
  };
  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {
      setExistingData(data?.data);
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  console.log("existingData", existingData);

  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // backgroundColor: "rgba(0, 0, 0, 0.5)",
    pointerEvents: "none",
  };

  return (
    <div style={{ marginBottom: "15px" }}>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
      </Backdrop> */}
      <div>
        <div className={styles.searchformasc}>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Name{" "}
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {defaultValue?.name}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Company Type
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {defaultValue?.company_type}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Authorized Officer Name
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {defaultValue?.complience_officer_name}
              </label>
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Authorized Officer Mobile
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {defaultValue?.complience_officer_mobile}
              </label>
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Authorized Officer Email
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {defaultValue?.complience_officer_email}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Created At
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {defaultValue?.created_at}
              </label>
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Agreement Document
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                {defaultValue && defaultValue?.agreement_doc && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(defaultValue.agreement_doc)}
                    className={styles.bbtnvofdevd}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                COI Document
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                {defaultValue && defaultValue?.coi_doc && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(defaultValue.coi_doc)}
                    className={styles.bbtnvofdevd}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          </div>

          <div className={styles.twoinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                RBI Certificate
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                {defaultValue && defaultValue?.nbfc_certificate && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(defaultValue.nbfc_certificate)}
                    className={styles.bbtnvofdevd}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Lender ID
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {defaultValue?.lender_unq}
              </label>
            </div>
            {/* <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Certificate Of Incorporation
                {existingData && existingData?.certificate_of_incorporation && (
                  <button
                    type="button"
                    onClick={() =>
                      handleViewPdf(existingData.certificate_of_incorporation)
                    }
                    className={styles.bbtnvofdevd}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Factory Address Proof{" "}
                {existingData && existingData?.factory_address_proof && (
                  <button
                    type="button"
                    onClick={() =>
                      handleViewPdf(existingData.factory_address_proof)
                    }
                    className={styles.bbtnvofdevd}
                  >
                    View
                  </button>
                )}
              </label>
            </div> */}
          </div>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth="lg"
            onFocus={handleFocus}
            onBlur={handleBlur}
            BackdropProps={{
              onClick: () => setOpen(false),
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              <button
                onClick={() => setOpen(false)}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 10,
                  backgroundColor: "white",
                  border: "none",
                  cursor: "pointer",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  width: "300px",
                }}
              >
                Close
              </button>

              <iframe
                src={pdfUrl}
                style={{
                  width: "100%",
                  height: "2000px",
                  border: "none",
                  pointerEvents: "none",
                }}
              />

              <div style={overlayStyle} />
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default ViewDet;
