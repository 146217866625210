import React, { useState, useEffect } from "react";
import styles from "./MonthBtn.module.css";
import { TbHexagons } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
const TotalDash = ({ dashboardList, selectedMonth, perm }) => {
  const navigate = useNavigate();
  // const handleSignIn = (val) => {
  //   // console.log("val", val);
  //   navigate("/b2cAdmin/borrower-list", {
  //     state: { val, selectedMonth },
  //   });
  // };
  const handleSignIn = (val) => {
    // console.log("val", val);
    // if (perm.includes("read")) {
    //   navigate("/borrower-list", {
    //     state: { val, selectedMonth },
    //   });
    // } else {
    //   console.log("You do not have permission to view this page.");
    // }
  };
  return (
    <div style={{ marginTop: "20px", display: "grid" }}>
      <div className={styles.monthBoxes}>
        {/* {applicationData.map((app, index) => ( */}
        <div className={styles.ttldv} onClick={() => handleSignIn("")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>
              Total Application{/* {app.title} */}
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {dashboardList?.total_application}
              </p>
              <div style={{ background: "#299cdb" }} className={styles.icocont}>
                <TbHexagons size={28} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("1")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>
              Registration{/* {app.title} */}
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {dashboardList?.step1_count}
              </p>
              <div style={{ background: "#299cdb" }} className={styles.icocont}>
                <TbHexagons size={28} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("2")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>KYC</p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {dashboardList?.step2_count}
              </p>
              <div style={{ background: "#25a546" }} className={styles.icocont}>
                <TbHexagons size={28} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("3")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>Agreement Sign</p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {dashboardList?.step3_count}
              </p>
              <div style={{ background: "#45cb85" }} className={styles.icocont}>
                <TbHexagons size={28} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("4")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>PDD</p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {dashboardList?.step4_count}
              </p>
              <div style={{ background: "#f06548" }} className={styles.icocont}>
                <TbHexagons size={28} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>Loan Amount</p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {dashboardList?.total_loan_amount}
              </p>
              <div style={{ background: "#ffbe0b" }} className={styles.icocont}>
                <TbHexagons size={28} />
              </div>
            </div>
          </div>
        </div>
        {/* ))} */}
      </div>
    </div>
  );
};

export default TotalDash;
