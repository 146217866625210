import React from "react";
import profl from "./../../../icons/profl.jpg";
import styles from "./SeprateDet.module.css";
const LoanDet = ({ details }) => {
  return (
    <div className={styles.brwdettcon}>
      <div className={styles.wholetxtcon}>
        <div className={styles.fourtxtcon}>
          <div className={styles.actcontfrtxt}>
            <p className={styles.ptpfrtxt}>Borrower ID</p>
            <p className={styles.pbtfrtxt}>
              {details?.loan_details?.borrower_id}
            </p>
          </div>
          <div className={styles.actcontfrtxt}>
            <p className={styles.ptpfrtxt}>Loan Id</p>
            <p className={styles.pbtfrtxt}>
              {" "}
              {details?.loan_details?.loanId || ""}
            </p>
          </div>
          {/* <div className={styles.actcontfrtxt}>
            <p className={styles.ptpfrtxt}>Application Data</p>
            <p className={styles.pbtfrtxt}>
              <button className={styles.bleviewbtn}>View</button>
            </p>
          </div> */}
          <div className={styles.actcontfrtxt}>
            <p className={styles.ptpfrtxt}>Loan Status</p>
            <p className={styles.pbtfrtxt}>
              {" "}
              {details?.loan_details?.status || ""}
            </p>
          </div>
          <div className={styles.actcontfrtxt}>
            <p className={styles.ptpfrtxt}>Net Loan Amount</p>
            <p className={styles.pbtfrtxt}>
              {details?.loan_details?.loan_amount || ""}
            </p>
          </div>
        </div>
        <div className={styles.fourtxtcon}>
          <div className={styles.actcontfrtxt}>
            <p className={styles.ptpfrtxt}>Product</p>
            <p className={styles.pbtfrtxt}>
              {" "}
              {details?.loan_details?.model_name || ""}
            </p>
          </div>
          <div className={styles.actcontfrtxt}>
            <p className={styles.ptpfrtxt}>EMI Amount</p>
            <p className={styles.pbtfrtxt}>
              {" "}
              {details?.loan_details?.emi || ""}
            </p>
          </div>
          <div className={styles.actcontfrtxt}>
            <p className={styles.ptpfrtxt}>Tenure</p>
            <p className={styles.pbtfrtxt}>
              {details?.loan_details?.tenure || ""}
            </p>
          </div>
          <div className={styles.actcontfrtxt}>
            <p className={styles.ptpfrtxt}>Paid Amount</p>
            <p className={styles.pbtfrtxt}>
              {details?.loan_details?.paid_amount}
            </p>
          </div>
        </div>
        <div className={styles.fourtxtcon} style={{ width: "33.5%" }}>
          <div className={styles.actcontfrtxt}>
            <p className={styles.ptpfrtxt}>Amount Due</p>
            <p className={styles.pbtfrtxt}>
              {details?.loan_details?.amount_due}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanDet;
