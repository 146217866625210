// import React, { createContext, useState, useEffect } from "react";

// const AuthContext = createContext();

// const AUTH_KEY = "#$cfcjnugby52484";

// const AuthProvider = ({ children }) => {
//   const [auth, setAuth] = useState(() => {
//     const savedAuth = localStorage.getItem("auth");
//     return savedAuth === AUTH_KEY;
//   });

//   useEffect(() => {
//     if (auth) {
//       localStorage.setItem("auth", AUTH_KEY);
//     } else {
//       localStorage.removeItem("auth");
//     }
//   }, [auth]);

//   const login = () => setAuth(true);
//   const logout = () => setAuth(false);

//   return (
//     <AuthContext.Provider value={{ auth, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export { AuthContext, AuthProvider };
import React, { createContext, useState, useEffect, useContext } from "react";

export const AuthContext = createContext({
  user: null,
  token: null,
  activeSubmenuId: null,
  baseURL: "http://13.200.65.87/api/",
  baseDoc: "http://13.200.65.87/",
  ver: "v2/",
  // baseURL: "https://insta.finayo.tech/api/",
  // baseDoc: "https://insta.finayo.tech/",
  // ver: "v1/",
  setUser: () => {},
  setToken: () => {},
  setActiveSubmenuId: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [token, _setToken] = useState(localStorage.getItem("ACCESS_TOKEN"));
  const [activeSubmenuId, setActiveSubmenuId] = useState(null);

  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("ACCESS_TOKEN", token);
    } else {
      localStorage.removeItem("ACCESS_TOKEN");
      localStorage.removeItem("borrowerListFilters");
      localStorage.removeItem("borrowerListB2CFilters");
      localStorage.removeItem("borrowerListB2BFilters");
      localStorage.removeItem("DealerListFilters");
      localStorage.removeItem("ProductListFilters");
    }
  };
  useEffect(() => {
    const monitorToken = setInterval(() => {
      const storedToken = localStorage.getItem("ACCESS_TOKEN");

      if (!storedToken) {
        setToken(null);
        setUser({});
      }
    }, 1000);

    return () => clearInterval(monitorToken);
  }, [token]);
  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        setUser,
        baseURL: "http://13.200.65.87/api/",
        baseDoc: "http://13.200.65.87/",
        ver: "v2/",
        // baseURL: "https://insta.finayo.tech/api/",
        // baseDoc: "https://insta.finayo.tech/",
        // ver: "v1/",
        setToken,
        activeSubmenuId,
        setActiveSubmenuId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
