import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import sampleimg from "./../../../icons/sampleimg.png";
import { Backdrop } from "@mui/material";
// import RemarkModal from "./RemarkModal";

const ZoomImg = ({ zoomImgUrl, addHandle, borrowerId }) => {
  console.log("zoomImgUrl", zoomImgUrl);
  const [bimg, setBimg] = useState(false);

  const imgHandle = (url) => {
    setBimg((val) => !val);
  };
  // const isPdf = (url) => url?.endsWith(".pdf");
  const isPdf = (url) => typeof url === "string" && url.endsWith(".pdf");
  return (
    <form
      style={{ minWidth: "400px" }}
      className={styles.modalcontainerr}
      onClick={(event) => event.stopPropagation()}
    >
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={bimg}
        onClick={imgHandle}
      >
        <RemarkModal
          addHandle={addHandle}
          imgHandle={imgHandle}
          borrowerId={borrowerId}
        />
      </Backdrop> */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            gap: "10px",
            position: "relative",
            top: "5px",
            margin: "15px",
          }}
        >
          {" "}
          {/* <button
            style={{
              width: "80px",
              borderRadius: "5px",
              background: "green",
              color: "#fff",
              border: "none",
            }}
            type="button"
          >
            Approve
          </button>
          <button
            style={{
              width: "80px",
              borderRadius: "5px",
              background: "red",
              color: "#fff",
              border: "none",
            }}
            type="button"
            onClick={() => imgHandle()}
          >
            Reject
          </button> */}
        </div>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            height: "11px",
            position: "relative",
            top: "3px",
            right: "5px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red", display: "flex", gap: "10px" }}
      >
        {/* <img src={zoomImgUrl} style={{ width: "600px", maxHeight: "500px" }} /> */}
        {isPdf(zoomImgUrl) ? (
          <iframe
            src={zoomImgUrl}
            style={{ width: "600px", height: "500px" }}
            title="PDF Preview"
          />
        ) : (
          <img
            src={zoomImgUrl}
            style={{ width: "600px", maxHeight: "500px" }}
            alt="Zoomed Document"
          />
        )}
      </div>

      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            {/* <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button> */}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ZoomImg;
