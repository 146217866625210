import React, { useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { MdElectricRickshaw, MdOutlineNavigateNext } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import OtherInputs from "./OtherInputs";
const VerifyAadhar = () => {
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [type, setType] = useState(1);
  const [add, setAdd] = useState(false);
  const [totalPage, setTotalPage] = useState(2);
  const navigate = useNavigate();
  const handleEditRow = (typee) => {
    setType(typee);
    // navigate("/someotherpage", {
    //   state: { type },
    // });
  };
  console.log({ type });
  const location = useLocation();
  const { val } = location.state || "";
  console.log("val", val);

  const addHandle = () => {
    setAdd((val) => !val);
  };
  const onSubmit = async (data) => {
    let payload;
    payload = {
      mobile: data?.mobile,
    };
    console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url: "admin/account-verfication-send-otp",
    method: "post",
    invalidateKey: "langList",
    successCallback: () => {
      addHandle();
    },
  });
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <Breadcum pageName="Verify Aadhar & PAN" />
        <div className={classes["main-body"]} style={{ padding: "15px" }}>
          <div
            className={classes.tablco}
            style={{ borderBottom: "1px solid #ddd" }}
          >
            <div
              style={{
                marginTop: "10px",
                borderTop: "1px solid #ddd",
                padding: "15px 0",
              }}
            >
              {" "}
              <div className={classes.totalcheckconnn}>
                <div className={classes.searorinp}>
                  <label
                    style={{
                      fontSize: "12px",
                      color: "#212529",
                      fontWeight: "bold",
                    }}
                  >
                    Aadhar Number
                    <span className={classes.requ}>*</span>
                  </label>
                  <div style={{ display: "flex", width: "100%" }}>
                    <input
                      {...register("name", { required: true })}
                      type="text"
                      style={{ borderRadius: " 5px 0 0 5px ", width: "300px" }}
                      className={classes.inputstst}
                    />
                    <button
                      type="button"
                      style={{
                        background: "#fa6548",
                        border: "none",
                        color: "#fff",
                        borderRadius: "  0 5px 5px 0  ",
                        fontSize: "11px",
                      }}
                    >
                      Verify
                    </button>
                  </div>
                </div>
                <div className={classes.searorinp}>
                  <label
                    style={{
                      fontSize: "12px",
                      color: "#212529",
                      fontWeight: "bold",
                    }}
                  >
                    PAN Number
                    <span className={classes.requ}>*</span>
                  </label>
                  <div style={{ display: "flex", width: "100%" }}>
                    <input
                      {...register("name", { required: true })}
                      type="text"
                      style={{ borderRadius: " 5px 0 0 5px ", width: "300px" }}
                      className={classes.inputstst}
                    />
                    <button
                      type="button"
                      style={{
                        background: "#45cb85",
                        border: "none",
                        color: "#fff",
                        borderRadius: "  0 5px 5px 0  ",
                        fontSize: "11px",
                      }}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", margin: "8px 0" }}>
                <input type="checkbox" />
                <p
                  style={{
                    color: "#212529",
                    fontSize: "13px",
                    position: "relative",
                    top: "2.5px",
                    textAlign: "left",
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing
                  elit.Accusamus velit blanditiis maiores nesciunt aliquam
                  aliquid voluptatibus.
                </p>
              </div>
              <div
                className={classes.buttonasc}
                style={{ marginTop: "10px", textAlign: "left" }}
              >
                {/* {!defaultValue && ( */}
                <button
                  type="submit"
                  className={classes.Subbtn}
                  style={{ width: "80px", background: "#45cb85" }}
                >
                  Submit
                </button>
                {/* )} */}
              </div>
            </div>
            {/* end Here  */}
            <OtherInputs />
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={add}
              onClick={addHandle}
            >
              <AddModal addHandle={addHandle} />
            </Backdrop>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyAadhar;
