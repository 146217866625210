import React from "react";
import classes from "./CustomConfirmBox.module.css";
import { Backdrop, Box } from "@mui/material";
import CloseIcon from "../../../icons/close.png";
import { MdDelete } from "react-icons/md";
// import CloseIcon from "../../../icons/Close";
import { GoAlertFill } from "react-icons/go";
// import DeleteConfirmIcon from "../../../icons/DeleteConfirmIcon";
const closeBtn = {
  cursor: "pointer",
  position: "absolute",
  //   margin: "-25px 0 0 0",
  right: 0,
  float: "right",
  textDecoration: "none",
  color: "#000000",
  padding: "6px 12px 4px 12px",
  fontSize: "18px",
};
const backDropStyle = {
  width: "350px",
  minHeight: "150px !important",
  background: "#fff",
  //   height: "100%",
  padding: "20px 25px;",
  position: "relative",
};
export default function CustomActInBox({
  onConfirm,
  onCancel,
  data,
  accessor,
  message,
  dataKey,
  additionalMessage,
  open,
}) {
  console.log({ onCancel });
  console.log(data);
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: 1000,
      }}
      open={open}
      onClick={onCancel}
    >
      <Box
        // sx={{ ...backDropStyle }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        className={classes.boxContiner}
        style={{ minHeight: "150px" }}
      >
        <div style={{ ...closeBtn }} onClick={onCancel} title="Close">
          <img src={CloseIcon} alt="Close" width={11} height={11} />
        </div>
        <div className={classes.content}>
          <div className={classes.titleContainer}>
            <GoAlertFill
              size="20px"
              style={{ color: "#12468a", position: "relative", bottom: "1px" }}
            />
            <h3> Alert</h3>
          </div>
          <div className={classes.confirmMessage}>
            {message || "Are you sure you want to delete "}
            {/* <span>{dataKey || ""}</span>{" "} */}
            <span className={classes.contentTitle}>
              {/* {data.name ? `"${data.name}"` : `"${data[accessor]}"`} */}
              {/* {data.name
                ? `"${data.name}"`
                : data[accessor]
                ? `"${data[accessor]}"`
                : ""} */}
              {additionalMessage && <span>{additionalMessage}</span>}
              <span style={{ textAlign: "center" }}> ?</span>
            </span>
          </div>

          <div className={classes.submitBtn}>
            <button type="button" className={classes.clear} onClick={onCancel}>
              {/* Cancel */}
              No
            </button>
            <button type="button" className={classes.sbmt} onClick={onConfirm}>
              {/* Submit */}
              Yes
            </button>
          </div>
        </div>
      </Box>
    </Backdrop>
  );
}
